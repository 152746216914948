import { SearchOutlined } from "@ant-design/icons";
import { Button, Input, Table } from "antd";
import * as math from "mathjs";
import React, { useCallback, useMemo, useRef, useState } from "react";
import Highlighter from "react-highlight-words";
import { useTranslation } from "react-i18next";
import {
  PRESET,
  useWindowDimensions,
} from "../../../../../../../igDashboards/view/hooks/useWindowDimensions";

export const applyFormula = ({
  value,
  gain,
  formula
}) => {
  const hasFormula = Boolean(formula) && formula !== null;
  
  try {
    const calculated = hasFormula ? math.evaluate(formula, { x: value }) : value * (gain || 1);
    
    return +calculated.toFixed(2);
  } catch (error) {

    return value * (gain || 1);
  }

}

// Função para formatar leitura anterior
const combinePrevReadingsFormatter = (cell, row) => {
  const leituraAnterior = row.leituraAnterior[0]?.consumption || 0;
  const leituraInicial = row.leituraInicial || 0;
  const gain = row.gain || 1;

  const calculatedValue = applyFormula({
    value: leituraAnterior,
    gain,
    formula: row.formulaGain
  });

  return (
    <div className="d-flex flex-column justify-content-center align-items-center">
      <b>{row.leituraAnterior[0].timestamp}</b>
      {(calculatedValue + leituraInicial).toFixed(2)}
    </div>
  );
};

// Função para formatar leitura atual
const combineReadingsFormatter = (cell, row) => {
  const leituraAnterior =
    row.leituraAtual?.consumption || row.leituraAnterior[0]?.consumption || 0;
  const leituraInicial = row.leituraInicial || 0;
  const gain = row.gain || 1;

  const calculatedValue = applyFormula({
    value: leituraAnterior,
    gain,
    formula: row.formulaGain
  });

  return (
    <div className="d-flex flex-column justify-content-center align-items-center">
      <b>{row.leituraAtual?.timestamp || row.leituraAnterior[0].timestamp}</b>
      {(calculatedValue + leituraInicial).toFixed(2)}
    </div>
  );
};

// Função para gerar filtros
const generateFilters = (data, dataIndex) => {
  const uniqueValues = [
    ...new Set(data.map((item) => item[dataIndex] ?? "N/A")),
  ];
  return uniqueValues.map((value) => ({ text: value, value }));
};

export const WaterTable = React.memo(({ data }) => {
  const { t } = useTranslation();
  const { preset, height } = useWindowDimensions();

  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");

  // Referência para o input de busca
  const searchInput = useRef(null);

  // Função de busca
  const handleSearch = useCallback((selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  }, []);

  const handleReset = useCallback((clearFilters) => {
    clearFilters();
    setSearchText("");
  }, []);

  const getColumnSearchProps = useCallback(
    (dataIndex) => ({
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{ padding: 8 }}>
          <Input
            ref={searchInput}
            placeholder={`Search ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{ marginBottom: 8, display: "block" }}
          />
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90, marginRight: 8 }}
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </div>
      ),
      filterIcon: (filtered) => (
        <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
      ),
      onFilter: (value, record) =>
        record[dataIndex]
          ? record[dataIndex]
              .toString()
              .toLowerCase()
              .includes(value.toLowerCase())
          : "",
      onFilterDropdownVisibleChange: (visible) => {
        if (visible) {
          setTimeout(() => searchInput.current.select(), 100);
        }
      },
      render: (text) =>
        searchedColumn === dataIndex ? (
          <Highlighter
            highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={text ? text.toString() : ""}
          />
        ) : (
          text
        ),
    }),
    [searchedColumn, searchText, handleSearch, handleReset]
  );

  // Definição das colunas
  const columns = useMemo(
    () => [
      {
        title: t("water.consumptionUnit"),
        dataIndex: "waterucs_description",
        key: "waterucs_description",
        width: 50,
        fixed: "left",
        sorter: (a, b) =>
          a.waterucs_description.localeCompare(b.waterucs_description),
        ...getColumnSearchProps("waterucs_description"),
      },
      {
        title: t("water.meter"),
        dataIndex: "registerNo",
        key: "registerNo",
        width: 50,
        ...getColumnSearchProps("registerNo"),
      },
      {
        title: "Model",
        dataIndex: "model",
        key: "model",
        width: 50,
        filters: generateFilters(data, "model"),
        onFilter: (value, record) => record.model.indexOf(value) === 0,
      },
      {
        dataIndex: "combinedReadings",
        key: "combinedReadings",
        width: 50,
        title: t("water.previousValue"),
        render: combinePrevReadingsFormatter,
        sorter: (a, b) =>
          a.leituraAnterior[0].consumption - b.leituraAnterior[0].consumption,
      },
      {
        dataIndex: "leituraAtual.consumption",
        key: "leituraAtual.consumption",
        width: 50,
        title: t("water.currentValue"),
        render: combineReadingsFormatter,
        sorter: (a, b) =>
          a.leituraAnterior[0].consumption - b.leituraAnterior[0].consumption,
      },
    ],
    [data, t, getColumnSearchProps]
  );

  return (
    <Table
      columns={columns}
      dataSource={data}
    
      size="small"
      scroll={{ x: true, y: preset === PRESET.MOBILE ? 280 : height - 580 }}
    />
  );
});
