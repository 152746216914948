import {
  AppstoreOutlined,
  BellOutlined,
  DiffOutlined,
  SettingOutlined,
} from "@ant-design/icons";

import {LuMapPin} from 'react-icons/lu'
import {FaWpexplorer} from 'react-icons/fa'
import { useQuery } from "@tanstack/react-query";
import { Badge, DatePicker, Menu, Table, Typography } from "antd";
import axios from "axios";
import moment from "moment";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Card, Modal, ModalBody, ModalHeader } from "reactstrap";
import {
  AlarmTurnOffIcon,
  PriorityBadge,
} from "../../../../igAlarms/alarmView/AlarmView";
import {
  PRESET,
  useWindowDimensions,
} from "../../../../igDashboards/view/hooks/useWindowDimensions";
import { useAPI } from "../SolarProvider";

export const NavBarSolar = ({ toggle = null }) => {
  const { t } = useTranslation();
  const {
    processedAt,
    selectDate,
    setSelectDate,
    handleToggleModal,
    setLoading,
    data,
    projectId,
    isMapPage,
    openMapPage,
    closeMapPage,
    page,
    openExplorerPage,
    closeExplorerPage,
  } = useAPI();
  const [showModalAlarms, setShowModalAlarms] = useState(false);
  const [internToogle, setInternToogle] = useState(false);

  const userType = localStorage.getItem("userType");

  const disabledDate = (current) => {
    // Impede a seleção de meses após o mês atual
    return current && current > moment().endOf("month");
  };

  const dateFormat = "YYYY-MM-DD";
  const { preset } = useWindowDimensions();
  const { data: alarms } = useQuery({
    queryKey: ["alarmProjectActive", { projectId }], // Inclui sorting na queryKey
    queryFn: async () => {
      try {
        const response = await axios.get("alarmProjectActive/", {
          params: {
            projId: projectId,
          },
        });
        return response.data;
      } catch (error) {
        console.error(error);
        return { totalAlarms: 0 };
      }
    },
    refetchInterval: 5 * 60 * 1000, // Atualiza a cada 5 minutos
  });
  return (
    <Card className="solar-header-controls mb-2">
      <Typography.Text>
        Processado em:{" "}
        {moment(processedAt ? processedAt * 1000 : new Date()).format("llll")}
      </Typography.Text>

      <DatePicker
        value={moment(selectDate, dateFormat)}
        onChange={(date) => {
          if (!date) return;
          setSelectDate(new Date(date));
          setLoading(true);
        }}
        format={"MM/YYYY"}
        picker="month"
        placeholder={t("solar.selectMonth")}
        disabledDate={disabledDate}
      />

      <Menu
        mode={preset === PRESET.MOBILE ? "inline" : "horizontal"}
        className="justify-content-end"
      >
        <Menu.Item
          icon={<LuMapPin />}
          onClick={() => (isMapPage ? closeMapPage() : openMapPage())}
        >
          {page === "map" ?t("preferences.title"): t("solar.mapFloorPlan")}
        </Menu.Item>
        <Menu.SubMenu
          disabled={!data.general}
          title={t("solar.reportsMenu")}
          icon={<DiffOutlined />}
        >
          <Menu.Item
            icon={<AppstoreOutlined />}
            onClick={() => handleToggleModal("modalPDF")}
          >
            {t("solarReport.reportPDF")}
          </Menu.Item>
          <Menu.Item
            icon={<AppstoreOutlined />}
            onClick={() => handleToggleModal("modalXLS")}
          >
            {t("solarReport.reportXLS")}
          </Menu.Item>
          <Menu.Item
            icon={<AppstoreOutlined />}
            onClick={() => handleToggleModal("modalReportManager")}
          >
            {t("reportManager.title")}
          </Menu.Item>
          <Menu.Item
            icon={<AppstoreOutlined />}
            onClick={() => handleToggleModal("modalBillingReport")}
          >
            {t('solar.financial')}
          </Menu.Item>
        </Menu.SubMenu>

        {userType !== "3" && (
          <Menu.Item
            icon={<SettingOutlined />}
            onClick={() => handleToggleModal("config")}
          >
            {t('solar.settings')}
          </Menu.Item>
        )}

        <Menu.Item
          icon={
            <Badge count={alarms?.totalAlarms} overflowCount={9} size="small">
              <BellOutlined />
            </Badge>
          }
          onClick={() => setShowModalAlarms(true)}
        >
          {t('solar.alarms')}
        </Menu.Item>

        
          <Menu.Item
            icon={<FaWpexplorer />}
            onClick={() => {
              page === "explorer" ? closeExplorerPage() : openExplorerPage();
            }}
          >
            {page === "explorer"  ? t("preferences.title") : "Data Explorer"}
          </Menu.Item>
        
      </Menu>
      {showModalAlarms && (
        <AlarmModal
          visible={showModalAlarms}
          onClose={() => setShowModalAlarms(false)}
          projectId={projectId}
        />
      )}
    </Card>
  );
};

const countAlarms = 10;

const AlarmModal = ({ visible, onClose, projectId }) => {
  const [page, setPage] = useState(1);
  const [sorting, setSorting] = useState({}); // Objeto para armazenar ordenação
  const { data: alarms, isLoading } = useQuery({
    queryKey: ["alarmProjectFilter", { projectId, page, countAlarms, sorting }], // Inclui sorting na queryKey
    queryFn: async () => {
      try {
        const response = await axios.get("alarmProjectFilter/", {
          params: {
            active: true,
            inactive: true,
            high: true,
            moderate: true,
            low: true,
            projects: [projectId],
            searchField: "",
            offset: (page - 1) * countAlarms,
            maxAlarms: countAlarms,
            sortBy: sorting.field, // Adiciona parâmetros de ordenação
            sortOrder: sorting.order,
          },
        });
        return response.data;
      } catch (error) {
        console.error(error);
        throw error; // Re-lança o erro para o react-query lidar com ele
      }
    },
    refetchOnWindowFocus: false,
  });
  const { t } = useTranslation();
  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      fixed: "left",
      width: "50px",
      sorter: (a, b) => {
        return a.id - b.id;
      },
      sortOrder: sorting.field === "id" ? sorting.order : null, // Mostra a ordem atual
    },
    {
      title: t("alarms.alarm"),
      dataIndex: "alarmName",
      key: "alarmName",
      width: "180px",
      align: "center",
      render: (cell, row) =>
        cell ? cell : row.isFromDriver === 1 ? "Driver Alarm" : "-",
    },
    {
      title: t("alarms.deviceName"),
      dataIndex: "deviceName",
      key: "deviceName",
    },
    {
      // variável
      dataIndex: "varName",
      title: t("alarms.varName"),
      width: "120px",
      render: (cell) =>
        cell === null
          ? "-"
          : cell.length > 20
          ? cell.slice(0, 17) + "..."
          : cell,
    },
    {
      // valor/unidade
      dataIndex: "value",
      title: t("alarms.value"),
      width: "80px",
      render: (cell, row) => cell + (row.varUnit ? " " + row.varUnit : null),
    },
    {
      // prioridade
      dataIndex: "priority",
      title: t("alarms.priority"),
      render: (cell) => <PriorityBadge priority={cell} />,
      width: "80px",
      align: "center",
    },
    {
      // data de ocorrencia
      dataIndex: "dateOccurred",
      title: t("alarms.dateOcc"),
      sorter: (a, b) => a.dateOccurred - b.dateOccurred,
      sortOrder: sorting.field === "dateOccurred" ? sorting.order : null,
      render: (cell) => {
        if (cell) {
          return moment(cell * 1000).format("DD/MM/YYYY HH:mm:ss");
        } else return "-";
      },
    },
    {
      // data de finalização
      dataIndex: "dateFinished",
      title: t("alarms.dateOff"),
      sorter: (a, b) => a.dateFinished - b.dateFinished,
      sortOrder: sorting.field === "dateFinished" ? sorting.order : null,

      render: (cell) => {
        if (cell) {
          return moment(cell * 1000).format("DD/MM/YYYY HH:mm:ss");
        } else return "-";
      },
    },
    {
      // mensagem ON
      dataIndex: "messageOn",
      title: t("alarms.onMsg"),
      width: "200px",
      render: (cell) => (
        <ul>
          {cell.split(/,|\./).map((item, key) => (
            <li key={key}>{item}</li>
          ))}
        </ul>
      ),
    },
    {
      // mensagem OFF
      dataIndex: "messageOff",
      title: t("alarms.offMsg"),
      width: "200px",
      render: (cell) =>
        !cell ? (
          "-"
        ) : (
          <ul>
            {cell?.split(/,|\./).map((item, key) => (
              <li key={key}>{item}</li>
            ))}
          </ul>
        ),
    },
    {
      title: t("alarms.actions"),
      dataIndex: "actions",
      key: "actions",
      align: "center",
      width: "80px",
      render: (_, row) => (
        <AlarmTurnOffIcon
          alarm={row}
          tableReload={() => {}}
          showTooltip={false}
        />
      ),
    },
  ];

  const handleTableChange = (pagination, filters, sorter) => {
    setPage(pagination.current);
    setSorting(sorter); // Atualiza o estado de ordenação
  };

  return (
    <Modal
      title="Alarmes"
      isOpen={visible}
      toggle={onClose}
      footer={null}
      centered
      size="xl"
    >
      <ModalHeader toggle={onClose}>Histórico de Alarmes</ModalHeader>
      <ModalBody>
        {isLoading ? (
          <p>Carregando...</p>
        ) : alarms && alarms.alarms.length === 0 ? (
          <p>Nenhum alarme encontrado</p>
        ) : (
          <Table
            dataSource={alarms.alarms}
            columns={columns}
            rowKey="id"
            size="small"
            pagination={{
              current: page,
              total: alarms.totalAlarms,
              pageSize: countAlarms,
              showSizeChanger: false,
            }}
            onChange={handleTableChange} // Adiciona o evento onChange
            scroll={{
              y: 400,
              x: "max-content",
            }}
          />
        )}
      </ModalBody>
    </Modal>
  );
};
