import React, { useMemo } from "react";
import { Row, Col } from "reactstrap";

import DataCard from "../DataCard";
import { useTranslation } from "react-i18next";
import { applyFormula } from "./components/WaterTable";
export const MeterInfo = ({ meterInfo }) => {
  const { t } = useTranslation();
  const leituraAnterior = useMemo(() => {
    const leituraAnterior = meterInfo?.leituraAnterior[0]?.consumption || 0;
    const leituraInicial = meterInfo?.leituraInicial || 0;
    const gain = meterInfo?.gain || 1;
    const formulaGain = meterInfo?.formulaGain;

    const calculatedValue = applyFormula({
      value: leituraAnterior,
      gain,
      formula: formulaGain,
    });

    return {
      value: (calculatedValue + leituraInicial).toFixed(2),
      timestamp: meterInfo?.leituraAnterior[0]?.timestamp ?? t("none"),
    };
  }, [meterInfo, t]);

  const leituraAtual = useMemo(() => {
    const leituraAtual = meterInfo?.leituraAtual?.consumption;
    const leituraAnterior = meterInfo?.leituraAnterior[0]?.consumption;
    const leituraInicial = meterInfo?.leituraInicial || 0;
    const gain = meterInfo?.gain || 1;
    const formulaGain = meterInfo?.formulaGain;

    const calculatedValue = applyFormula({
      value: leituraAtual || leituraAnterior || 0,
      gain,
      formula: formulaGain,
    });

    return {
      value: (calculatedValue + leituraInicial).toFixed(2),
      timestamp: meterInfo?.leituraAtual?.timestamp || meterInfo?.leituraAnterior[0]?.timestamp || t("none"),
    };
  }, [meterInfo, t]);

  return (
    <div className="mx-0">
      <Row>
        <Col>
          <DataCard
            title={t("water.initialRead")}
            value={meterInfo?.leituraInicial ?? t("none")}
            unit="m³"
            dark
          />
        </Col>
        <Col>
          <DataCard
            title={t("water.previousValue")}
            value={leituraAnterior.value}
            unit="m³"
            dark
            timestamp={leituraAnterior.timestamp}
          />
        </Col>
        <Col>
          <DataCard
            title={t("water.currentValue")}
            value={leituraAtual.value}
            unit="m³"
            dark
            timestamp={leituraAtual.timestamp}
          />
        </Col>
      </Row>
    </div>
  );
};
