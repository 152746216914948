import { faSignal } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, List, Typography } from "antd";
import React from "react";
import 'react-leaflet-markercluster/styles';
import {
  PRESET,
  useWindowDimensions,
} from "../../../../igDashboards/view/hooks/useWindowDimensions";
import { useTranslation } from "react-i18next";

const { Title } = Typography;

/**
 * @typedef {Object} Device
 * @property {number} id - Identificador único do dispositivo.
 * @property {string} description - Nome do dispositivo.
 * @property {string} manufacturer - Fabricante do dispositivo.
 * @property {string} model - Modelo do dispositivo.
 * @property {number} lastComm - Última comunicação.
 * @property {number} commInterval - Intervalo de comunicação.
 * @property {Null | string} image - Imagem do dispositivo.
 * @property {number} status - Status do dispositivo.
 * @property {number} stringsPerMPPT - Strings por MPPT.
 * @property {RealtimeData} realtimeData - Dados em tempo real do dispositivo.
 * @property {string} color - Cor do dispositivo.
 * @property {Array<number>} position - Posição do dispositivo no mapa.
 * @property {boolean} addedToMap - Indica se o dispositivo foi adicionado ao mapa.
 */

/**
 * Componente que exibe uma lista de dispositivos.
 *
 * @param {Object} props - Propriedades do componente.
 * @param {Device[]} props.devices - Lista de dispositivos a serem exibidos.
 * @param {Object<string, {onMap: boolean}>} props.positions - Posições dos dispositivos no mapa.
 * @param {Function} props.onAddToMap - Função chamada quando um dispositivo é adicionado ao mapa.
 * @param {Function} props.onRemoveFromMap - Função chamada quando um dispositivo é removido do mapa.
 * @param {boolean} props.isEditable - Indica se a lista é editável.
 * @param {boolean} props.loading - Indica se a lista está carregando.
 * @returns {JSX.Element} Elemento JSX que representa a lista de dispositivos.
 */
const DeviceList = ({
  devices,
  positions,
  onAddToMap,
  onRemoveFromMap,
  isEditable,
  loading
}) => {
  const { preset } = useWindowDimensions();
  const {t} = useTranslation()
  return (
    <div
      style={{
        width: "100%",
        margin: "0 auto",
        paddingInline: [PRESET.MOBILE, PRESET.MOBILE].includes(preset)
          ? 0
          : "1rem",
      }}
    >
      <Title level={3}>{t("projects.devices")}</Title>
      <ul
        style={{
          listStyle: "none",
          padding: 0,
          margin: 0,
          height: "100%",
          maxHeight: [PRESET.MOBILE, PRESET.MOBILE].includes(preset)
            ? "400px"
            : "56vh",
          overflowY: "auto",
          width: "100%",
          gap: "10px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        {devices.map((device) => {
          const onMap = positions[device.id]?.onMap;

          return (
            <List.Item
              key={device.id}
              style={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                border: "1px solid #e8e8e8",
                borderRadius: "5px",
                padding: 0,
                background: "var(--sidebar-background)",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flex: 1,
                  width: "100%",
                  alignItems: "center",
                  justifyContent: "space-between",
                  paddingRight: "10px",
                  
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flex: 1,
                    width: "100%",
                    alignItems: "center",
                    padding: "0 10px",
                    borderRadius: "5px",
                    margin: "5px 0",
                    gap: "10px",
                  }}
                >
                  <img
                    src={
                      device.image ??
                      require("../../../../../assets/devices/noDevice.png")
                    }
                    alt={device.description}
                    style={{
                      width: "50px",
                      height: "50px",
                      objectFit: "cover",
                    }}
                  />
                  <div>
                    <Title level={5}>{device.description}</Title>
                    <p>
                      {device.manufacturer} - {device.model}
                    </p>
                  </div>
                </div>
                <FontAwesomeIcon
                  icon={faSignal}
                  fixedWidth
                  className={"align-middle fa-blink"}
                  style={{
                    color: device?.status
                      ? "rgb(26, 202, 26)"
                      : "rgb(247, 51, 51)",
                    marginLeft: "5px",
                  }}
                />
              </div>
              {isEditable && (
                <Button
                  type="primary"
                  onClick={() =>
                    !onMap ? onAddToMap(device.id) : onRemoveFromMap(device.id)
                  }
                  disabled={!isEditable || loading}
                  style={{
                    borderRadius: "0 0 5px 5px",
                    width: "100%",
                    padding: "10px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    background: loading
                      ? "var(--disabled)"
                      : !onMap
                      ? "var(--primary-color)"
                      : "var(--danger-color)",
                    border: "none",
                    color: "white",
                    cursor: "pointer",
                  }}
                >
                  {onMap ? t('solar.removeFromMap') : t('solar.addToMap')}
                </Button>
              )}
            </List.Item>
          );
        })}
      </ul>
    </div>
  );
};

export default DeviceList;
