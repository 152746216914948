import { faSignal } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import {
  Breadcrumb,
  BreadcrumbItem,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Row,
  Spinner,
  Tooltip,
  UncontrolledTooltip,
} from "reactstrap";
import Header from "../../../../components/Header";
import HeaderTitle from "../../../../components/HeaderTitle";

import { SearchOutlined } from "@ant-design/icons";
import { Button, Input, Space, ConfigProvider, Table } from "antd";
import Highlighter from "react-highlight-words";
import { useQuery } from "@tanstack/react-query";

export const TableWaterPlants = ({ plantList, maxHeight }) => {
  const { t } = useTranslation();
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const getColumnSearchProps = (dataIndex, isLink = false) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Buscar ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Buscar
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Limpar
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filtrar
          </Button>
          <Button type="link" size="small" onClick={() => close()}>
            Fechar
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    render: (text, props) =>
      searchedColumn === dataIndex ? (
        isLink ? (
          <Link to={`/project-${props.id}-water`}>
            <Highlighter
              highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
              searchWords={[searchText]}
              autoEscape
              textToHighlight={text ? text.toString() : ""}
            />
          </Link>
        ) : (
          <Highlighter
            highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={text ? text.toString() : ""}
          />
        )
      ) : isLink ? (
        <Link to={`/project-${props.id}-water`}>{text}</Link>
      ) : (
        text
      ),
  });

  const columns = useMemo(
    () => [
      {
        title:  t("homePage.pvWater"),
        dataIndex: "name",
        key: "name",
        width: 150,
        sorter: (a, b) => a.name.localeCompare(b.name),
        ...getColumnSearchProps("name", true),
      },
      {
        title: t('homePage.status'),
        dataIndex: "status",
        key: "status",
        width: 20,
        align: "center",
        filters: [
          { text: t("devices.status.noComm"), value: 0 },
          { text: t("devices.status.partial"), value: 1 },
          { text: t("devices.status.online"), value: 2 },
        ],
        onFilter: (value, record) => record.status === value,
        render: (_, record) => <Status plant={record} />
      },
      {
        title: t("homePage.totalConsumption") + " (m³)",
        dataIndex: "totalConsumption",
        key: "totalConsumption",
        width: 150,
        sorter: (a, b) => a.totalConsumption - b.totalConsumption,
        ...getColumnSearchProps("totalConsumption"),
      },
    ],
    [searchText, searchedColumn]
  );

  return (
    <ConfigProvider>
      <Card>
        <CardHeader className="d-flex align-items-center justify-content-between">
          <h4 className="">{`${t("solar.plants")}`}</h4>
        </CardHeader>
        <CardBody
          className="styleScroll"
          style={{
            padding: 0,
          }}
        >
          <Table
            columns={columns}
            dataSource={plantList}
            rowKey="id"
            pagination={{ pageSize: 10 }}
            scroll={{ y: maxHeight || 47 * 10 }}
            size="small"
          />
        </CardBody>
      </Card>
    </ConfigProvider>
  );
};

function Status({ plant }) {
  const { id, status } = plant;

  const { t } = useTranslation();

  const color = {
    0: "red",
    1: "#FFA500",
    2: "green",
  };

  const classNamesIcon = {
    0: "fa-blink",
    1: "",
    2: "",
  };

  const toolTip = {
    0: t("solar.tooltipOff"),
    1: t("solar.tooltipWarning"),
    2: t("solar.tooltipOn"),
  };

  return (
    <div id={"tumb" + id} className="d-flex justify-content-center">
      <FontAwesomeIcon
        icon={faSignal}
        fixedWidth
        className={"align-middle " + classNamesIcon[status]}
        style={{ marginBottom: "2px", color: color[status] }}
      />
      <UncontrolledTooltip placement="top" target={"tumb" + id}>
        {toolTip[status]}
      </UncontrolledTooltip>
    </div>
  );
}


export const WaterModule = ({ activeTab }) => {
  const { t } = useTranslation();

  const { data, status } = useQuery({
    queryKey: ["water/listPlants"],
    queryFn: async () => {
      const response = await axios.get("v2/water/listPlants", {
        params: {
          monthRef: new Date(),
        },
      });
      return response.data;
    },
  });

  const loading = status === "loading";

  return (
    <>
      <Container fluid>
        <Header>
          <HeaderTitle>{t("homePage.waterVision")}</HeaderTitle>
          <Breadcrumb>
            <BreadcrumbItem>
              <Link to="/">{t("sidebar.home")}</Link>
            </BreadcrumbItem>
            <BreadcrumbItem active>{t("homePage.water")}</BreadcrumbItem>
          </Breadcrumb>
        </Header>
        <div>
          {data ? (
            <div>
              <Row sm={12}>
                <Col>
                  <TableWaterPlants plantList={data} />
                </Col>
              </Row>
            </div>
          ) : loading ? (
            <div className="text-center mb-4" style={{ marginTop: "300px" }}>
              {" "}
              <Spinner color="primary" />
            </div>
          ) : (
            <Card>
              <CardBody
                style={{
                  marginTop: "54px",
                  height: "94px",
                  textAlign: "center",
                }}
              >
                {t("homePage.noWaterDataPlants")}
              </CardBody>
            </Card>
          )}
        </div>
      </Container>
    </>
  );
};
