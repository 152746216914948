import * as htmlToImage from "html-to-image";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { getScreenshotImage } from "../../../../utils/functions";
import { ModalSolarSettings } from "./ModalSolarSettings";
import CurrentDayPower from "./components/CurrentDayPower";
import DailyEnergy from "./components/DailyEnergy";
import EnergyYield from "./components/EnergyYield";

import AvoidedEmissions from "./components/AvoidedEmissions";
import CurrentPower from "./components/CurrentPower";
import DevicesTable from "./components/DevicesTable";
import WeeklyEnergy from "./components/Income";
import InstalledCapacity from "./components/InstalledCapacity";
import LifetimeEnergy from "./components/LifetimeEnergy";
import MonthlyEnergy from "./components/MonthlyEnergy";

import "leaflet/dist/leaflet.css";
import { useHistory } from "react-router-dom";
import { Card, CardBody, Col, Row, Spinner } from "reactstrap";
import { DataExplorerInProject } from "../../../../components/DataExplorerInProject";
import ShowIf from "../../../../components/ShowIf";
import {
  PRESET,
  useWindowDimensions,
} from "../../../igDashboards/view/hooks/useWindowDimensions";
import GeraçãoXirradiação from "./GeracaoXIrradiacao";
import LateralPerformanceCards from "./LateralPerformanceCard";
import ModalSelectReport from "./ModalSelectReport";
import { SolarProvider, useAPI } from "./SolarProvider";
import PvSystRatio from "./SolarView/components/PVSystRatio";
import { WeatherContainer } from "./SolarView/components/WeatherContainer";
import Availability from "./components/Availability";
import MapDevices from "./components/Map";
import { NavBarSolar } from "./components/NavBarSolar";

const SolarModuleWithProvider = ({ projectId, activeTab }) => {
  return (
    <SolarProvider projectId={projectId}>
      <SolarModule activeTab={activeTab} />
    </SolarProvider>
  );
};

const SolarModule = ({ activeTab }) => {
  const {
    projectId,
    projectName,
    data,
    monthlyDataUsage,
    selectDate,
    loading,
    loadingModal,
    reloadData,
    reportManagerBarChart,
    setReportManagerBarChartScreenshoot,
    handleToggleModal,
    toggleModal,
    page
  } = useAPI();

  const userType = localStorage.getItem("userType");
  const [pdfScreenshot, setPdfScreenshot] = useState("");

  const { t } = useTranslation();

  const monthlyUsageScreenshot = () => {
    let renderImage = document.getElementById("chartdiv" + projectId);
    htmlToImage.toPng(renderImage).then(function (canvas) {
      setPdfScreenshot(canvas);
    });
  };

  const currentDate = new Date();
  var mesSelecionado = !selectDate
    ? currentDate
    : new Date(selectDate.getFullYear(), selectDate.getMonth() + 1, 0);

  let history = useHistory();

  useEffect(() => {
    // Configura o intervalo para recarregar a cada 18 minutos
    const reloadFunction = setInterval(reloadData, 18 * 60 * 1000);
    if (history.location.pathname === "solar" && activeTab === "solar") {
      clearInterval(reloadFunction);
    }
    return () => {
      clearInterval(reloadFunction);
    };
  }, [activeTab, history.location.pathname, reloadData]);

  useEffect(() => {
    let timer = setTimeout(() => {
      getScreenshotImage("reportManagerBarChart")
        .then((canvas) => {
          setReportManagerBarChartScreenshoot(canvas);
        })
        .catch((error) => {
          console.error(
            "Error during screenshot capture for reportManagerBarChart:",
            error
          );
        });
    }, 4000);

    return () => clearTimeout(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportManagerBarChart]);

  let jsonData = [];

  function getDataToSend(data) {
    jsonData.push(data);
  }

  const { preset } = useWindowDimensions();

  return loading ? (
    <Card>
      <CardBody>
        <div className="text-center p-3">
          <Spinner color="secondary" />
        </div>
      </CardBody>
    </Card>
  ) : data.devices ? (
    <div>
      {/* Se existe algum device solar, mostra a página carregada */}
      {data.devices ? (
        <div>
          <NavBarSolar />
          {data?.general?.weather && (
            <WeatherContainer weatherData={data.general?.weather} />
          )}

          <ModalSelectReport
            jsonData={jsonData}
            getDataToSend={getDataToSend}
            projectName={projectName}
            monthlyDataUsage={monthlyDataUsage}
            opacity={0}
            monthlyUsageScreenshot={monthlyUsageScreenshot}
            pdfScreenshot={pdfScreenshot}
            monthRef={!selectDate ? Date.now() : Date.parse(selectDate)}
            data={data}
            projectId={projectId}
          />
          {toggleModal === "config" ? (
            <ModalSolarSettings
              open={toggleModal === "config"}
              toggle={() => handleToggleModal(false)}
            />
          ) : null}

          {data.general && (
            <Row
              className="d-flex flex-row mr-0"
              style={
                preset !== PRESET.MOBILE
                  ? {
                      minWidth: 1100,
                      overflowX: "auto",
                      flexWrap: "nowrap",
                    }
                  : null
              }
            >
              {/* <Row  xl="10" lg="12" md="12" sm="10" xs="1"> */}
              <Col
                style={{
                  marginRight: 0,
                }}
              >
                <DailyEnergy
                  dailyEnergy={data.general.dailyEnergy}
                  loadingModal={loadingModal}
                />
              </Col>
              <Col
                style={{
                  marginRight: 0,
                }}
              >
                <WeeklyEnergy
                  weeklyEnergy={data.weeklyEnergy}
                  loadingModal={loadingModal}
                />
              </Col>
              <Col
                style={{
                  marginRight: 0,
                }}
              >
                <MonthlyEnergy
                  monthlyEnergy={data.general.monthlyEnergy}
                  loadingModal={loadingModal}
                />
              </Col>
              <Col
                style={{
                  marginRight: 0,
                }}
              >
                <LifetimeEnergy
                  lifetimeEnergy={data.general.lifetimeEnergy}
                  loadingModal={loadingModal}
                />
              </Col>
              <Col
                style={{
                  marginRight: 0,
                }}
              >
                <CurrentPower
                  currentPower={data.general.currentPower}
                  loadingModal={loadingModal}
                />
              </Col>
              <Col
                style={{
                  marginRight: 0,
                }}
              >
                <InstalledCapacity
                  capacity={data.general.capacity}
                  loadingModal={loadingModal}
                />
              </Col>
              <Col
                style={{
                  marginRight: 0,
                }}
              >
                <AvoidedEmissions
                  avoidedCO2={data.general.avoidedCO2}
                  loadingModal={loadingModal}
                />
              </Col>
              <Col
                style={{
                  marginRight: 0,
                }}
              >
                <PvSystRatio
                  PvSystRatio={data.general.pvSystRatio}
                  loadingModal={loadingModal}
                />
              </Col>
            </Row>
          )}
        </div>
      ) : (
        <Card className="text-center">
          <CardBody className="py-6">{t("solar.noSolarDevices")}.</CardBody>
        </Card>
      )}

      {data.general ? (
        <div>
          {toggleModal === "config" ? (
            <ModalSolarSettings
              open={toggleModal === "config"}
              toggle={() => handleToggleModal(false)}
            />
          ) : null}

          <ShowIf condition={page!=='map'}>
            <Row>
              <Col lg={2} style={{ paddingRight: "4px", marginBottom: "8px" }}>
                <Card
                  style={{
                    marginBottom: "8px",
                    height: "100%",
                    paddingBottom: "8px",
                  }}
                >
                  <DevicesTable
                    devices={data.devices}
                    activeTab={activeTab}
                    projId={projectId}
                    allData={data}
                  />
                </Card>
              </Col>

              <Col>
                {page === 'explorer' ? (
                  <DataExplorerInProject projectId={projectId} />
                ) : (
                  <>
                    <Row>
                      <Col
                        xs={12}
                        lg={userType === "3" ? 12 : 6}
                        style={{ paddingRight: "4px", paddingLeft: "4px" }}
                      >
                        <Card
                          style={{
                            marginBottom: "8px",
                            display: "flex",
                            height: "calc(100% - 8px)",
                          }}
                        >
                          <EnergyYield
                            projectId={projectId}
                            month={mesSelecionado}
                          />
                        </Card>
                      </Col>
                      {userType === "3" ? null : (
                        <Col
                          xs={12}
                          lg={6}
                          style={{ paddingRight: "4px", paddingLeft: "4px" }}
                        >
                          <Card
                            style={{
                              marginBottom: "8px",
                              display: "flex",
                              height: "calc(100% - 8px)",
                            }}
                          >
                            <Availability
                              projectId={projectId}
                              month={mesSelecionado}
                            />
                          </Card>
                        </Col>
                      )}
                    </Row>
                    <Row>
                      <Col
                        xs={12}
                        lg={6}
                        style={{ paddingRight: "4px", paddingLeft: "4px" }}
                      >
                        <Card
                          style={{
                            marginBottom: "8px",
                            display: "flex",
                            height: "calc(100% - 8px)",
                          }}
                        >
                          <CurrentDayPower
                            data={data}
                            projectId={projectId}
                            month={mesSelecionado}
                          />
                        </Card>
                      </Col>
                      <Col
                        xs={12}
                        lg={6}
                        style={{ paddingRight: "4px", paddingLeft: "4px" }}
                      >
                        <Card
                          style={{
                            marginBottom: "8px",
                            display: "flex",
                            height: "calc(100% - 8px)",
                          }}
                        >
                          <GeraçãoXirradiação
                            data={data}
                            projectId={projectId}
                            month={mesSelecionado}
                          />
                        </Card>
                      </Col>
                    </Row>
                  </>
                )}
              </Col>

              <Col lg={2} style={{ paddingLeft: "4px", marginBottom: "8px" }}>
                <LateralPerformanceCards data={data} projectId={projectId} />
              </Col>
            </Row>
          </ShowIf>
          <ShowIf condition={page === 'map'}>
            <Row>
             <MapDevices devices={data.devices}
             />
            </Row>
          </ShowIf>
        </div>
      ) : (
        <Card className="text-center">
          <CardBody className="py-6">{t("solar.noParams")}.</CardBody>
        </Card>
      )}
    </div>
  ) : (
    <Card className="text-center">
      <CardBody className="py-6">{t("solar.noSolarDevices")}.</CardBody>
    </Card>
  );
};

export default SolarModuleWithProvider;
