// MapDevices.js
import { faSignal } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useQuery } from "@tanstack/react-query";
import { Form, Switch } from "antd";
import axios from "axios";
import L from "leaflet";
import 'leaflet/dist/leaflet.css';
import React, { useMemo, useState } from "react";
import {
  ImageOverlay,
  MapContainer,
  Marker,
  Tooltip,
  useMapEvent
} from "react-leaflet";
import {
  PRESET,
  useWindowDimensions,
} from "../../../../igDashboards/view/hooks/useWindowDimensions";
import ModalDeviceVar from "../../ModalDeviceVar";
import { DevicesModal } from "../ModalDevice";
import { useAPI } from "../SolarProvider";
import DeviceList from "./DeviceList";
import { useTranslation } from "react-i18next";

function MapDevices() {
  const [isEditable, setIsEditable] = useState(false);
  const [deviceDatails, setDeviceDatails] = useState(false);
  const [color, setColor] = useState("");
  const [device, setDevice] = useState(null);
  const [deviceVars, setDeviceVars] = useState([]);
  const [selectedDevice, setSelectedDevice] = useState(null);
  const [dvcPositions, setDvcPositions] = useState({});
  const [deviceStatus, setDeviceStatus] = useState([]);
  const [loadingRequest, setLoadingRequest] = useState(false);
  const {t} = useTranslation()
  function openModal(modal) {
    setDeviceDatails(modal);
  }

  function closeModal() {
    setDeviceDatails(false);
  }
  const { preset } = useWindowDimensions();
  const { projectId, data, mapPlantImage } = useAPI();
  const { refetch, isLoading: loadingPosition } = useQuery({
    queryKey: ["devices/positions", projectId],
    queryFn: () => {
      return axios
        .get(`/getDevicesPosition?projId=${projectId}`)
        .then((res) => res.data);
    },
    enabled: !!projectId,
    onSuccess: (data) => {
      setDvcPositions(data);
    },
  });

  const { isLoading } = useQuery({
    queryKey: ["/projects/device/realtime", projectId],
    queryFn: () => {
      return axios
        .get(`/projects/device/realtime?projId=${projectId}`)
        .then((res) => res.data);
    },
    enabled: !!projectId,
    onSuccess: (data) => {
      setDeviceStatus(data);
    },
  });

  const handleAddDevice = async (id) => {
    setLoadingRequest(true);

    try {
      await axios.post("/saveDevicesPosition", {
        projId: projectId,
        idDevice: id,
        position: {
          latitude: 100,
          longitude: 100,
          onMap: true,
        },
      });
      refetch();
    } catch (error) {
      console.log(error);
    } finally {
      setLoadingRequest(false);
    }
  };

  const handleRemoveDevice = async (id) => {
    setLoadingRequest(true);

    try {
      await axios.post("/saveDevicesPosition", {
        projId: projectId,
        idDevice: id,
        position: {
          latitude: 0,
          longitude: 0,
          onMap: false,
        },
      });
      refetch();
    } catch (error) {
      console.log(error);
    } finally {
      setLoadingRequest(false);
    }
  };

  const handleEditDevice = async (id, newPosition) => {
    setLoadingRequest(true);
    try {
      await axios.post("/saveDevicesPosition", {
        projId: projectId,
        idDevice: id,
        position: {
          latitude: newPosition[0],
          longitude: newPosition[1],
          onMap: true,
        },
      });
      refetch();
    } catch (error) {
      console.log(error);
    } finally {
      setLoadingRequest(false);
    }
  };
  const wh = [500, 500];
  const origin = [0, 0];
  const bounds = [origin, wh];
  const userType = localStorage.getItem("userType");

  if (isLoading || loadingPosition) {
    return (
      <div className="align-items-center card d-flex flex-1 justify-content-center ml-3 mr-3 row w-100  p-4     ">
        <div className="spinner-border text-primary" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  }

  return (
    <div
      className="card row"
      style={{
        width: "100%",
        marginLeft: "12px",
        marginRight: "12px",
        padding: "20px",
        display: [PRESET.MOBILE, PRESET.TABLET].includes(preset)
          ? "flex"
          : "grid",
        gridTemplateColumns: "400px 2fr",
        flexDirection: "column",
      }}
    >
      <DeviceList
        devices={deviceStatus}
        positions={dvcPositions}
        onAddToMap={handleAddDevice}
        onRemoveFromMap={handleRemoveDevice}
        isEditable={isEditable}
        loading={loadingRequest}
      />
      {deviceDatails === "solar" && !!selectedDevice ? (
        <DevicesModal
          device={selectedDevice}
          open={deviceDatails === "solar"}
          toggle={closeModal}
          allData={data}
          state={color}
        />
      ) : null}
      {deviceDatails === "others" && !!selectedDevice ? (
        <ModalDeviceVar
          open={deviceDatails === "others"}
          toggle={closeModal}
          device={device}
          devices={deviceStatus}
          deviceVars={deviceVars}
          deviceID={selectedDevice}
        />
      ) : null}
      <MapContainer
        style={{ height: "60vh", minHeight: "60vh", width: "100%" }}
        bounds={bounds}
        boundsOptions={{
          padding: [0, 0],
        }}
        maxBounds={bounds}
        zoomSnap={0} // Important to disable snap after fitBounds
        whenReady={(e) => e.target.fitBounds(bounds)} // Have the map adjust its view to the same bounds as the Image Overlay
        minZoom={0}
      >
        <ImageOverlay
          url={mapPlantImage ?? ''}
          bounds={bounds}
          className="map_main"
        />
        {userType !== "3" && (
          <div
            style={{
              position: "absolute",
              top: 10,
              right: 10,
              zIndex: 1000,
              background: "var(--sidebar-background)",
              padding: 10,
              borderRadius: 5,
            }}
          >
            <Form>
              <Form.Item label={t('solar.edit')} name="edit" style={{ marginBottom: 0 }}>
                <Switch
                  checked={isEditable}
                  onChange={(checked) => setIsEditable(checked)}
                />
              </Form.Item>
            </Form>
          </div>
        )}
        <InnerMap
          dvcPositions={dvcPositions}
          deviceStatus={deviceStatus}
          isEditable={isEditable}
          handleEditDevice={handleEditDevice}
          setSelectedDevice={setSelectedDevice}
          setDevice={setDevice}
          openModal={openModal}
          setColor={setColor}
          setDeviceVars={setDeviceVars}
          loadingRequest={loadingRequest}
        />
      </MapContainer>
    </div>
  );
}
const InnerMap = ({
  dvcPositions,
  deviceStatus,
  isEditable,
  handleEditDevice,
  setSelectedDevice,
  setDevice,
  openModal,
  setColor,
  setDeviceVars,
  loadingRequest
}) => {

  const [zoomIndex, setZoomIndex] = useState(0);

  useMapEvent("zoom", (e) => {
    setZoomIndex(e.target._zoom);
  });

  const iconSize = useMemo(() => {

    if (zoomIndex <= 0) {
      return 10
    } else if (zoomIndex <= 1) {
      return 25
    } else if (zoomIndex <= 2) {
      return 50
    } else if (zoomIndex <= 3) {
      return 75
    } else if (zoomIndex <= 4) {
      return 90
    } else {
      return 110
    }
  }, [zoomIndex])

  const generateIcon = (device) => {
    const isOnline = device?.info?.status
    return L.icon({
      iconUrl:
        device?.info?.image ??
        require("../../../../../assets/devices/noDevice.png"),
      iconSize: [iconSize, iconSize],
      className: `border border-${isOnline ? "success" : "danger"} rounded-circle`,
    })
  }



  return (
    <>
      {Object.keys(dvcPositions)
        .map((key) => {
          return {
            id: key,
            ...dvcPositions[key],
            info: deviceStatus.find((device) => device.id === Number(key)),
          };
        })
        .filter((device) => device.onMap)
        .map((device) => (
          <Marker
            key={device.id}
            position={[device.latitude, device.longitude]}
            draggable={isEditable}
            eventHandlers={{
              dragend: (e) => {
                handleEditDevice(device.id, [
                  e.target.getLatLng().lat,
                  e.target.getLatLng().lng,
                ]);
              },
              click: () => {
                setSelectedDevice(device.id);
                setDevice(device);
                if (device.info.color) {
                  openModal("solar");
                  setColor(device.info.color);
                } else {
                  axios
                    .get("/readVariables", {
                      params: {
                        id: device.id,
                      },
                    })
                    .then((res) => {
                      setDeviceVars(res.data);
                      openModal("others");
                    })
                    .catch((err) => {
                      console.log(err);
                    });
                }
              },
            }}
            icon={generateIcon(device)}

          >
            <Tooltip
              direction="bottom"
              opacity={1}
              offset={[0, iconSize / 2]}
              className="d-flex flex-column align-items-center"
            >
              <span
                style={{
                  fontSize: 10
                }}
              >{device?.info?.description}</span>
              <FontAwesomeIcon
                icon={faSignal}
                fixedWidth
                className={"align-middle fa-blink"}
                style={{
                  color: device?.info?.status
                    ? "rgb(26, 202, 26)"
                    : "rgb(247, 51, 51)",
                  marginLeft: "5px",
                }}
              />
            </Tooltip>
          </Marker>
        ))}
      {
        loadingRequest && (
          <div className="align-items-center d-flex h-100 justify-content-center w-100 position-absolute" style={{
            background: "rgba(0, 0, 0, 0.5)",
            zIndex: 1000
          }}>
            <div className="spinner-border text-primary" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>

          </div>
        )
      }
    </>
  );
};

export default MapDevices;
