import {
  faBell,
  faCog,
  faPen,
  faPercentage,
  faSearch,
  faSort,
  faSortDown,
  faSortUp,
  faTrash,
  faUsersCog
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from 'axios';
import React, { useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import {
  ChevronDown,
  ChevronRight,
  ChevronsDown,
  ChevronsRight,
  Cpu,
  Plus,
} from "react-feather";
import { useTranslation } from 'react-i18next';
import { RiApps2AddFill } from "react-icons/ri";
import { toastr } from "react-redux-toastr";
import { Link } from "react-router-dom";
import {
  Breadcrumb, BreadcrumbItem,
  Button,
  Card, CardBody, CardHeader, CardTitle,
  Col,
  Container,
  Row,
  Spinner,
  UncontrolledTooltip,
} from "reactstrap";

import Header from "../../../components/Header";
import HeaderTitle from "../../../components/HeaderTitle";

import {
  ModalDeleteDevice,
  ModalDeleteGateway,
  ModalDeleteProject
} from "./ModalDeleteProject";
import {
  ModalAlarms,
  ModalDeviceGains,
  ModalEditDevice,
  ModalEditProject,
  ModalVariables
} from "./ModalEditProject";
import {
  ModalAddUser,
  ModalNewDevice,
  ModalNewProject
} from "./ModalNewProject";
import { ModalEditPlantModule } from "./components/ModalEditPlantModule";

/*NOTE: BOTÃO DE ADICIONAR PROJETO */
const HeadProjIcons = ({setLoadProjects}) => {
  const { t } = useTranslation();

  const [openNewProj, setOpenNewProj] = useState(false);
  //NOTE: ativa/desativa modal
  function toggleNewProj() {
    setOpenNewProj(!openNewProj);
  }

  return (
    <div>
      <Button 
        color="primary"
        id={"addProject"} 
        className="cursor-pointer"
        onClick={()=>setOpenNewProj(true)}
      >
        {t('projTable.addProject')}
      </Button>
      {(openNewProj) //NOTE: modal de adicionar projeto
        ? <ModalNewProject
            open={openNewProj}
            toggle={toggleNewProj}
            setLoadProjects={setLoadProjects}
          />
        : null
      }
    </div>
  )
};

/*NOTE: ÍCONES DE AÇÃO (PROJETOS) */
const ProjIcons = ({proj, setLoadProjects}) => {
  const { t } = useTranslation();

  const [openEditProj, setOpenEditProj] = useState(false);
  const [openDeleteProj, setOpenDeleteProj] = useState(false);
  const [openAddUsers, setOpenAddUsers] = useState(false);
  const [openEditPlantModule, setEditPlantModule] = useState(false);

  const userId = localStorage.getItem('userType');
  const userCompany = localStorage.getItem('companyId');

  //NOTE: ativa/desativa modais
  function toggleEditProj() {
    setOpenEditProj(!openEditProj);
  }
  function toggleDeleteProj() {
    setOpenDeleteProj(!openDeleteProj);
  }
  function toggleAddUser() {
    setOpenAddUsers(!openAddUsers);
  }
  function toggleEditPlantModule() {
    setEditPlantModule(!openEditPlantModule);
  }

  return (
    <div className="d-flex justify-content-left"
      style={{
        gap: "0.3rem"
      }}
    >
      {
        (userId === '1' && '1' === userCompany)
          ? <div //NOTE: ícone de editar módulos
              id={"editPlant"+proj.id}
              className="align-middle cursor-pointer"
              onClick={toggleEditPlantModule}
            >
              <FontAwesomeIcon icon={faCog} fixedWidth />
              {/***NOTE: modal de editar módulos do projeto  ***/}
              {(openEditPlantModule)
                ? <ModalEditPlantModule
                    open={openEditPlantModule}
                    toggle={toggleEditPlantModule}
                    proj={proj}
                    setLoadProjects={setLoadProjects}
                  />
                : null
              }
              {/*NOTE: Tooltip */}
              <UncontrolledTooltip
                placement="top"
                target={"editPlant"+proj.id}
              >
                {t('projTable.editModules')}
              </UncontrolledTooltip>
            </div>
          : null
      }

      <div //NOTE: ícone de editar projeto
        id={"editProj"+proj.id}
        className="align-middle cursor-pointer"
        onClick={()=>setOpenEditProj(true)}
      >
        <FontAwesomeIcon icon={faPen} fixedWidth />
        {/***NOTE: modal de editar projeto  ***/}
        {(openEditProj)
          ? <ModalEditProject
              open={openEditProj}
              toggle={toggleEditProj}
              proj={proj}
              setLoadProjects={setLoadProjects}
            />
          : null
        }
        {/*NOTE: Tooltip */}
        <UncontrolledTooltip
          placement="top"
          target={"editProj"+proj.id}
        >
          {t('projTable.editProject')}
        </UncontrolledTooltip>
      </div>

      <div //NOTE: ícone de remover projeto
        id={"deleteProj"+proj.id}
        className="align-middle cursor-pointer"
        onClick={()=>setOpenDeleteProj(true)}
      >
        <FontAwesomeIcon icon={faTrash} fixedWidth />
        {/***NOTE: modal de remover projeto  ***/}
        {(openDeleteProj)
          ? <ModalDeleteProject
              open={openDeleteProj}
              toggle={toggleDeleteProj}
              proj={proj}
              setLoadProjects={setLoadProjects}
            />
          : null
        }
        {/*NOTE: Tooltip */}
        <UncontrolledTooltip
          placement="top"
          target={"deleteProj"+proj.id}
        >
          {t('projTable.deleteProject')}
        </UncontrolledTooltip>
      </div>

      <div  //NOTE: ícone de gerenciar usuarios 
        id={"users"+proj.id}
        className="align-middle cursor-pointer"
        onClick={toggleAddUser}
      >
        <FontAwesomeIcon icon={faUsersCog} fixedWidth />
        {/***NOTE: modal de gerenciar usuário  ***/}
        {(openAddUsers)
          ? <ModalAddUser
              open={openAddUsers}
              toggle={toggleAddUser}
              proj={proj}
            />
          : null
        }
        {/*NOTE: Tooltip */}
        <UncontrolledTooltip
          placement="top"
          target={"users"+proj.id}
        >
          {t('projTable.manageUsers')}
        </UncontrolledTooltip>
      </div>
      
    </div>
  )
};


/*NOTE: ÍCONE DE ADICIONAR DISPOSITIVO */
const AddDeviceIcon = ({proj, setLoadDevices, deviceList}) => {
  const { t } = useTranslation();

  const [openAddDevice, setOpenAddDevice] = useState(false);  
  //NOTE: ativa/desativa modal
  function toggleAddDevice() {
    setOpenAddDevice(!openAddDevice);
  }

  return (
    <div //NOTE: ícone de adicionar dispositivo ao projeto
      id={"addDevice"+proj.id} 
      className="align-middle cursor-pointer"
      onClick={()=>setOpenAddDevice(true)}
    >
      <div>
        <Cpu width={16} height={16} />
        <Plus width={12} height={12} />
      </div>   
      {/***NOTE: modal de adicionar dispositivo  ***/}
      {(openAddDevice)
        ? <ModalNewDevice
            open={openAddDevice}
            toggle={toggleAddDevice}
            proj={proj}
            setLoadDevices={setLoadDevices}
            deviceList={deviceList}
          />
        : null
      }
      {/*NOTE: Tooltip */}
      <UncontrolledTooltip
        placement="top"
        target={"addDevice"+proj.id}
      >
        {t('projTable.addDevice')}
      </UncontrolledTooltip>   
    </div>
  );
};

/*NOTE: ÍCONES DE AÇÃO (DISPOSITIVOS) */
const DevIcons = ({dvc, setLoadDevices, deviceList}) => {
  const { t } = useTranslation();

  const [openEditDev, setOpenEditDev] = useState(false);
  const [openDeleteDev, setOpenDeleteDev] = useState(false);
  const [openDevGains, setOpenDevGains] = useState(false);
  const [openAlarms, setOpenAlarms] = useState(false);
  const [openModalVariables, setOpenModalVariables] = useState(false);

  //NOTE: ativa/desativa modais
  function toggleEditDev() {
    setOpenEditDev(!openEditDev);
  };
  function toggleDeleteDev() {
    setOpenDeleteDev(!openDeleteDev);
  };
  function toggleDevGains() {
    setOpenDevGains(!openDevGains);
  };
  function toggleAlarms() {
    setOpenAlarms(!openAlarms);
  };
  function toggleModalVariables() {
    setOpenModalVariables(!openModalVariables);
  };

  return (
    <div className="d-flex justify-content-left"
      style={{
        gap: "0.3rem"
      }}
    >
      <div //NOTE: ícone de editar
        id={"editDev"+dvc.id}
        className="align-middle cursor-pointer"
        onClick={()=>setOpenEditDev(true)}
      >
        <FontAwesomeIcon icon={faPen} fixedWidth />
        {/***NOTE: modal de editar dispositivo  ***/}
        {(openEditDev)
          ? <ModalEditDevice
              open={openEditDev}
              toggle={toggleEditDev}
              dvc={dvc}
              setLoadDevices={setLoadDevices}
              deviceList={deviceList}
            />
          : null
        }
        {/*NOTE: Tooltip */}
        <UncontrolledTooltip
          placement="top"
          target={"editDev"+dvc.id}
        >
          {t('projTable.editDevice')}
        </UncontrolledTooltip>
      </div>

      <div //NOTE: ícone de remover
        id={"deleteDev"+dvc.id}
        className="align-middle cursor-pointer"
        onClick={()=>setOpenDeleteDev(true)}
      >
        <FontAwesomeIcon icon={faTrash} fixedWidth />
        {/***NOTE: modal de remover dispositivo  ***/}
        {(openDeleteDev)
          ? (dvc.category === 'gw')
            ? <ModalDeleteGateway
                open={openDeleteDev}
                toggle={toggleDeleteDev}
                dvc={dvc}
                setLoadDevices={setLoadDevices}
                deviceList={deviceList}
              />
            : <ModalDeleteDevice
                open={openDeleteDev}
                toggle={toggleDeleteDev}
                dvc={dvc}
                setLoadDevices={setLoadDevices}
              />
          : null
        }
        {/*NOTE: Tooltip */}
        <UncontrolledTooltip
          placement="top"
          target={"deleteDev"+dvc.id}
        >
          {t('projTable.deleteDevice')}
        </UncontrolledTooltip>
      </div>

      <div //NOTE: ícone de ganhos
        id={"gains"+dvc.id}
        className="align-middle cursor-pointer"
        onClick={()=>setOpenDevGains(true)}
      >
        <FontAwesomeIcon icon={faPercentage} fixedWidth />
        {/***NOTE: modal de gerenciamento de fatores de multiplicação ***/}
        {(openDevGains)
          ? <ModalDeviceGains
              open={openDevGains}
              toggle={toggleDevGains}
              dvc={dvc}
            />
          : null
        }
        {/*NOTE: Tooltip */}
        <UncontrolledTooltip
          placement="top"
          target={"gains"+dvc.id}
        >
          {t('projTable.manageGains')}
        </UncontrolledTooltip>
      </div>
      <div //NOTE: ícone de ganhos
        id={"alarms"+dvc.id}
        className="align-middle cursor-pointer"
        onClick={()=>setOpenAlarms(true)}
      >
        <FontAwesomeIcon icon={faBell} fixedWidth />
        {/***NOTE: modal de gerenciamento de fatores de multiplicação ***/}
        {(openAlarms)
          ? <ModalAlarms
              open={openAlarms}
              toggle={toggleAlarms}
              dvc={dvc}
            />
          : null
        }
        {/*NOTE: Tooltip */}
        <UncontrolledTooltip
          placement="top"
          target={"alarms"+dvc.id}
        >
          {t('projTable.defaultAlarms')}
        </UncontrolledTooltip>
      </div>
      <div  //NOTE: ícone de gerenciar variáveis 
        id={"variables"+dvc.id}
        className="align-middle cursor-pointer"
        onClick={toggleModalVariables}
      >
        <RiApps2AddFill  />
        {/***NOTE: modal de gerenciar variáveis  ***/}
        {openModalVariables ? <ModalVariables
          open={openModalVariables}
          toggle={toggleModalVariables}
          dvc={dvc}
        /> : null}
        {/*NOTE: Tooltip */}
        <UncontrolledTooltip
          placement="top"
          target={"variables"+dvc.id}
        >
          {t('projTable.virtualVariables')}
        </UncontrolledTooltip>
      </div>
    </div>
  )
};

/***NOTE: TABELA DE PROJETOS ***/
const ProjectsTable = () => {
  const { t } = useTranslation();

  const [loadProjects, setLoadProjects] = useState(true);
  const [projectList, setProjectList] = useState([]);
  const [onSearch, setOnSearch] = useState(false);

  /*NOTE: Busca lista de projetos da API */
  useEffect(() => {
    if (loadProjects) {

      axios.get('/projectCompSearch')
      .then(res => {
        setProjectList(res.data)
        setLoadProjects(false)
      })
      .catch(err => {
        console.log(err);
        toastr.error('Erro!',"Erro ao buscar projetos.");  //NOTE: t() da problema com o useEffect
      });
    }
  }, [loadProjects]);

  /*NOTE: Exibe spinner enquanto a promise do axios não resolve */
  if (loadProjects && !projectList.length) {
    return <div className="text-center"><Spinner color="primary" /></div>;
  }

  /*NOTE: Configura o que é mostrado e como é mostrado */
  const colsProj = [
    { //NOTE: ID (oculto)
      dataField: "id",
      text: t('projTable.id'),
      sort: true,
      hidden: true,
      isKey: true
    },
    { //NOTE: nome do projeto
      dataField: "name",
      text: t('projTable.name'),
      sort: true,
    },
    { //NOTE: descricao
      dataField: "description",
      text: t('projTable.description'),
      formatter: cell => {
        return (cell.length > 20) 
          ? cell.slice(0,17) + "..."
          : cell
      }
    },
    { //NOTE: nome da empresa
      dataField: "companyName",
      text: t('projTable.company'),
      sort: true,
    },
    { //NOTE: botões de ação (editar/remover)
      dataField: "actions",
      text: t('projTable.actions'),
      headerStyle: { width: "11%" },
      formatter: (_, row) => (
        <div>
          <ProjIcons proj={row} setLoadProjects={setLoadProjects} />
        </div>
      )
    }
  ];

  /*NOTE: Ícones de ordenação */
  const sortIcon = {
    sortCaret: (order) => (!order) 
      ? <FontAwesomeIcon
        className={'fa-xs ml-1'}
        icon={faSort}
      />
      : (order === 'asc')
        ? <FontAwesomeIcon
          className={'fa-xs ml-1'}
          icon={faSortUp}
        />
        : <FontAwesomeIcon
            className={'fa-xs ml-1'}
            icon={faSortDown}
          />
  }

  /***NOTE: Configurações de expansão de linha ***/
  const expandRow = {
    renderer: row => {
      return <div>
        {/* <ProjectContent proj={row}/> */}
        <DeviceTable proj={row} />
      </div>
    },
    /*NOTE: Coluna de expansão - sinais > e V */
    showExpandColumn: true,
    expandByColumnOnly: true,
    expandHeaderColumnRenderer: ({ isAnyExpands }) =>
      isAnyExpands ? (
        <ChevronsDown className="cursor-pointer" width={16} height={16} />
      ) : (
        <ChevronsRight className="cursor-pointer" width={16} height={16} />
      ),
    expandColumnRenderer: ({ expanded }) =>
      expanded ? (
        <ChevronDown className="cursor-pointer" width={16} height={16} />
      ) : (
        <ChevronRight className="cursor-pointer" width={16} height={16} />
      )
  };

  const { SearchBar } = Search;  

  //NOTE: função de busca personalizável
  function onColumnMatch({
    searchText,
    value,
    column,
    row
  }) {    
    //NOTE: ignora acentos e letras maiúsculas
    // e limita busca às colunas de nome, descrição e empresa
    
    var normSearch = searchText
      .normalize("NFD").replace(/[\u0300-\u036f]/g, "")
      .toLowerCase();
    var normName = row.name
      .normalize("NFD").replace(/[\u0300-\u036f]/g, "")
      .toLowerCase();
    var normDesc = row.description
      .normalize("NFD").replace(/[\u0300-\u036f]/g, "")
      .toLowerCase();
    var normComp = row.companyName
      .normalize("NFD").replace(/[\u0300-\u036f]/g, "")
      .toLowerCase();

    if (normName.includes(normSearch)
      || normDesc.includes(normSearch)
      || normComp.includes(normSearch)
    ) {
      return true;
    } else {
      return false;
    }
  }

  /*NOTE: Retorna Tabela de Projetos e demais componentes */
  return (
    <div>
      {(projectList.length)
        ? (
          <ToolkitProvider
            bootstrap4
            keyField="id"
            data={ projectList }
            columns={ colsProj }
            search={ { onColumnMatch: onColumnMatch } }
          >
            {props => {
              return (
              <div>
                <Row className="mb-2">
                  <Col xs="4" className="d-flex align-items-center text-left">
                    {/*NOTE: {(onSearch) */}
                    {(onSearch)
                      ? <div className="d-flex justify-content-start">
                        <SearchBar
                          style={ {
                            width: '100%',
                            height: '22px',
                            fontSize: '10pt'
                          } }
                          placeholder={t('projTable.search')}
                          { ...props.searchProps }
                        />
                      </div>
                      : <FontAwesomeIcon
                          icon={faSearch}
                          fixedWidth
                          className="cursor-pointer"
                          onClick={()=>setOnSearch(true)}
                        />
                    }
                  </Col>
                  <Col xs="8" className="text-right">
                    <HeadProjIcons setLoadProjects={setLoadProjects} /> 
                  </Col>
                </Row>
                <BootstrapTable
                  condensed
                  bordered={false}
                  sort={sortIcon}
                  expandRow={expandRow}
                  { ...props.baseProps }
                />
              </div>
              )
            }}
          </ToolkitProvider>
        )
        : <div>
            <Row className="mb-2">
              <Col xs="4" className="text-left">
              </Col>
              <Col xs="8" className="text-right">
                <HeadProjIcons setLoadProjects={setLoadProjects} /> 
              </Col>
            </Row>
            <p className="text-center">{t('projTable.noProject')}.</p>
          </div>
      }  
    </div>
  );
};

/***NOTE: TABELA DE DISPOSITIVOS (SUB-TABELA) ***/
const DeviceTable = ({proj}) => {
  const { t } = useTranslation();

  const [loadDevices, setLoadDevices] = useState(true);
  const [deviceList, setDeviceList] = useState([]);

  /*NOTE: Busca lista de dispositivos da API */
  useEffect(() => {
    if(loadDevices) {
      axios.get('/deviceSearch', {params: {
        projId: proj.id
      }})
      .then(res => {
        setDeviceList(res.data)
        setLoadDevices(false)
      })
      .catch(err => {
        console.log(err);
        toastr.error('Erro!',"Erro ao buscar dispositivos.");  //NOTE: t() da problema com o useEffect
        setLoadDevices(false)
      });
    }
  }, [loadDevices, proj]);

  /*NOTE: Exibe spinner enquanto a promise do axios não resolve */
  if (loadDevices && !deviceList.length) {
    return (loadDevices)
      ? <div className="text-center"><Spinner color="primary" /></div>
      : <p>{t('projTable.noDevices')}.</p>
  }

  /*NOTE: Configura o que é mostrado e como é mostrado */
  const colsDevices = [ 
    { //NOTE: ID (oculto)
      dataField: "id",
      text: t('projTable.id'),
      sort: true,
      hidden: true,
      isKey: true
    },
    { //NOTE: nome de referencia do dispositivo
      dataField: "description",
      text: t('projTable.reference'),
      sort: true
    },
    { //NOTE: modelo do dispositivo
      dataField: "model",
      text: t('projTable.model'),
      sort: true
    },
    { //NOTE: hash
      dataField: "hash",
      text: t('projTable.hash'),
      sort: true,
    },
    { //NOTE: última comunicação
      dataField: "lastComm",
      text: t('projTable.lastComm'),
      sort: true,
      formatter: cell => {
        if (cell) {
          var cellDate = new Date(cell*1000);

          return cellDate.getDate().toString().padStart(2, '0') + '/' +
          (cellDate.getMonth()+1).toString().padStart(2, '0') + '/' +
          cellDate.getFullYear().toString().padStart(2, '0') + ' ' +
          cellDate.getHours().toString().padStart(2, '0') + ':' +
          cellDate.getMinutes().toString().padStart(2, '0') + ':' +
          cellDate.getSeconds().toString().padStart(2, '0')
        } else return '-'
      }
    },
    { //NOTE: tempo de atualização
      dataField: "commInterval",
      text: t('projTable.commInterval'),
      sort: true,
      formatter: cell => (cell) ? cell + ' s' : '-'
    },
    { //NOTE: ícones de ação (editar/remover)
      dataField: "actions",
      text: t('projTable.actions'),
      headerStyle: { width: "10%" },
      formatExtraData: {deviceList: deviceList},
      formatter: (cell, row, rowIndex, extra) => (
        <DevIcons 
          dvc={row} 
          setLoadDevices={setLoadDevices}
          deviceList={extra.deviceList}
        />
      )
    }
  ];

  /*NOTE: Ícones de ordenação */
  const sortIcon = {
    sortCaret: (order) => (!order)
      ? <FontAwesomeIcon
        className={'fa-xs ml-1'}
        icon={faSort}
      />
      : (order === 'asc')
        ? <FontAwesomeIcon
          className={'fa-xs ml-1'}
          icon={faSortUp}
        />
        : <FontAwesomeIcon
            className={'fa-xs ml-1'}
            icon={faSortDown}
          />
  }

  return <div className='my-2'>
    <Row>
      <Col xs="10" className="text-left">
        <h6 className="text-muted">{t('projTable.devices')}:</h6>
      </Col>
      <Col xs="2" className="d-flex justify-content-center">
        <AddDeviceIcon 
          proj={proj} 
          setLoadDevices={setLoadDevices}
          deviceList={deviceList}
        />
      </Col>
    </Row>
    
    {(deviceList.length)
      ? (
        <BootstrapTable
          bootstrap4
          keyField="id"
          data={deviceList}
          columns={colsDevices}
          condensed
          bordered={false}
          striped={true}
          sort={sortIcon}
        />
      )
      : <p>{t('projTable.noDevices')}.</p>
    }
  </div>
};

/***NOTE: PÁGINA ***/
const ProjectData = () => {
  const { t } = useTranslation();

  return (
    <Container fluid>
      <Header>
        <HeaderTitle>
          {t('projTable.title')}
        </HeaderTitle>

        <Breadcrumb>
          <BreadcrumbItem>
            <Link to="/">{t('sidebar.home')}</Link>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <Link to="/">{t('sidebar.registers')}</Link>
          </BreadcrumbItem>
          <BreadcrumbItem active>
            {t('sidebar.projectsData')}
          </BreadcrumbItem>
        </Breadcrumb>
      </Header>

      <Card style={{marginTop: "-26px"}}>
        <CardHeader>
          <CardTitle tag="h5">
            {t('projTable.head')}
          </CardTitle>
          <h6 className="card-subtitle text-muted mt-1">
            {t('projTable.text1')} 
            <ChevronRight width={16} height={16} /> 
            {t('projTable.text2')}.
          </h6>
        </CardHeader>

        <CardBody>
          <ProjectsTable />
        </CardBody>
      </Card>
    </Container>
  );
};

export default ProjectData;
