import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { DateRangeSelector } from "../../../../../components/DateRangeSelector";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import {
  Card,
  Modal,
  ModalBody,
  ModalHeader,
  Nav,
  NavItem,
  NavLink,
  Spinner,
} from "reactstrap";
import classnames from "classnames";

const ENUM_ENTRY_RANGE = {
  today: "minutes",
  last24Hours: "minutes",
  last7Days: "days",
  last30Days: "days",
  last12Months: "months",
  "24Hours": "minutes",
  "7D": "days",
  fullMonth: "days",
  fullYear: "months",
};

export const OnlineControlGraphic = ({ companyId, open, close }) => {
  const [sendParams, setSendParams] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const [range, setRange] = useState("24Hours");

  const [mergeVariables, setMergeVariables] = useState(true);
  const [checkedList, setCheckedList] = useState([]);
  const [variables, setVariables] = useState([]);
  const [selectedVariables, setSelectedVariables] = useState([]);
  const [autoSelect, setAutoSelect] = useState(false);

  const [initDate, setEntrieStartDate] = useState(
    moment().startOf("day").toDate()
  );
  const [finishDate, setEntrieEndDate] = useState(
    moment().endOf("day").toDate()
  );

  const { data, isLoading } = useQuery({
    queryKey: [
      "nocOnlineControlGraphic",
      companyId,
      initDate,
      finishDate,
      range,
    ],
    queryFn: async () => {
      const res = await axios.get("v2/noc/onlineControlGraphic", {
        params: {
          companyId,
          initDate,
          finishDate,
          range: ENUM_ENTRY_RANGE[range],
        },
      });
      return res.data;
    },
    refetchInterval: 1000 * 60 * 60,
  });
  return (
    <Modal isOpen={open} toggle={close}>
      <ModalBody>
          <DateRangeSelector
            loading={isLoading}
            initDate={initDate}
            finishDate={finishDate}
            setEntrieStartDate={setEntrieStartDate}
            setEntrieEndDate={setEntrieEndDate}
            entrieRange={range}
            setEntrieRange={setRange}
            notShowFilters={[
              "7D",
              "today",
              "last24Hours",
              "last7Days",
              "last30Days",
              "last12Months",
              "fullMonth",
              "fullYear",
            ]}
          >
            {isLoading ? (
              <Spinner />
            ) : (
              <>
                {data?.data && <ChartComponent2 data={data.data} />}
              </>
            )}
          </DateRangeSelector>
      </ModalBody>
    </Modal>
  );
};

const ChartComponent2 = ({ data }) => {
  const { t } = useTranslation();

  const [activeTab, setActiveTab] = useState("1");

  // Determina os dados a serem exibidos com base na aba ativa
  const getChartData = () => {
    if (activeTab === "1") return data.water_gas || [];
    if (activeTab === "2") return data.power || [];
    if (activeTab === "3") return data.solar || [];
    return [];
  };

  const chartData = getChartData();

  return (
    <>
      <div style={{ marginBottom: "40px" }}>
        {/* Seletor para escolher entre as categorias */}
        <Nav tabs>
          {data.water_gas && (
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === "1" })}
                onClick={() => setActiveTab("1")}
              >
                Água/Gás
              </NavLink>
            </NavItem>
          )}
          {data.power && (
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === "2" })}
                onClick={() => setActiveTab("2")}
              >
                Energia
              </NavLink>
            </NavItem>
          )}
          {data.solar && (
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === "3" })}
                onClick={() => setActiveTab("3")}
              >
                Solar
              </NavLink>
            </NavItem>
          )}
        </Nav>

        {/* Gráfico referente à aba ativa */}
        {chartData.length > 0 ? (
          <LineChart width={500} height={300} data={chartData}>
            <CartesianGrid stroke="#ccc" />
            <XAxis dataKey="time" />
            <YAxis tickFormatter={(value) => `${(value * 100).toFixed(2)}%`} />
            <Tooltip formatter={(value) => `${(value * 100).toFixed(2)}%`} />
            <Legend />
            <Line
              type="monotone"
              dataKey="online"
              stroke="#82ca9d"
              dot={false}
              name="Online"
            />
            <Line
              type="monotone"
              dataKey="offline"
              stroke="#8884d8"
              dot={false}
              name="Offline"
            />
            <Line
              type="monotone"
              dataKey="noComm"
              stroke="#ff7300"
              dot={false}
              name="Nunca Comunicou"
            />
          </LineChart>
        ) : (
          <p>{t("No data available for the selected category.")}</p>
        )}
      </div>
    </>
  );
};
