import packageJson from "../../package.json";

import AlfaCompLogo from "../assets/img/alfacomp-logo.png";
import EngmLogo from "../assets/img/engm-blue3.png";
import iGoalLogo from "../assets/img/igoal-logo.png";
import Monte from "../assets/img/monte.webp";
import NorLogo from "../assets/img/nor-logo.png";
import Sollnet from "../assets/img/sollnet.jpeg";
import VetorLogLogo from "../assets/img/vetorlogImage.png";
import Vppbrasil from "../assets/img/vppbrasil.png";
import WattsOnLogo from "../assets/img/watts.jpeg";
import tkLogo from "../assets/img/tk_logo.png";
import dexLogo from "../assets/img/Dex-logo.png";
import impetusLogo from "../assets/img/impetusLogo.png";
import siseco from "../assets/img/siseco.png";
import youOn from "../assets/img/youOn.png";

export const getDomain = () => {
  const domain = window.location.host;

  switch (domain) {
    case "alfacomp.igoaltecnologia.com":
      return "https://alfacomp.net/";
    case "nor.igoaltecnologia.com":
      return "https://norengineering.com.br/";
    case "engm.igoaltecnologia.com":
      return "https://engm.com.br/";
    case "vetorlog.igoaltecnologia.com":
      return "https://vetorlog.com/";
    case "wattson.igoaltecnologia.com":
      return "https://wattson.com.br";
    case "monte.igoaltecnologia.com":
      return "https://www.monte.rs";
    default:
      return "https://igoaltecnologia.com/";
  }
};

export const getCompanyName = () => {
  const domain = window.location.host;

  return domain === "alfacomp.igoaltecnologia.com"
    ? "AlfaComp"
    : domain === "nor.igoaltecnologia.com"
    ? "NOR Engineering"
    : domain === "engm.igoaltecnologia.com"
    ? "ENGM IoT"
    : domain === "vetorlog.igoaltecnologia.com"
    ? "VetorLog"
    : domain === "wattson.igoaltecnologia.com"
    ? "Watts On"
    : domain === "monte.igoaltecnologia.com"
    ? "Montê"
    : domain === "youon.igoaltecnologia.com"
    ? "YouOn"
    : packageJson.author;
};

export const getCompanyLogo = () => {
  const domain = window.location.host;

  switch (domain) {
    case "alfacomp.igoaltecnologia.com":
      return AlfaCompLogo;
    case "nor.igoaltecnologia.com":
      return NorLogo;
    case "engm.igoaltecnologia.com":
      return EngmLogo;
    case "sollnet.igoaltecnologia.com":
      return Sollnet;
    case "monte.igoaltecnologia.com":
      return Monte;
    case "vetorlog.igoaltecnologia.com":
      return VetorLogLogo;
    case "wattson.igoaltecnologia.com":
      return WattsOnLogo;
    case "vppbrasil.igoaltecnologia.com":
      return Vppbrasil;
    case "tk.igoaltecnologia.com":
      return tkLogo;
    case "impetus.igoaltecnologia.com":
      return impetusLogo;
    case "siseco.igoaltecnologia.com":
      return siseco;
    case "app.iotdex.io":
      return dexLogo;
    case "youon.igoaltecnologia.com":
      return youOn;
    default:
      return iGoalLogo;
  }
};
