const enUsProjectTranslations = {
    projects: {
        title: "My Plants",
        devices: "Devices",
        solar: "Solar",
        energy: "Power",
        energyBreadcrumb: "Power (General View)",
        water: "Water",
        gas: "Gas",
        noBreak: "UPS",
        noProjects: "The user is not linked to any project",
        searchProjects: "Search projects",
        addProject: "Add Project",
    },
    projTable: {
        title: "Projects and Devices Overview",
        head: "Projects, devices and users registers",
  
        // NOTE: tables
        text1: "Please click on",
        text2: "to view the devices of each project",
        id: "#",
        name: "Name",
        description: "Description",
        company: "Company",
        address: "Address",
        neighborhood: "Neighborhood",
        city: "City",
        state: "State",
        zipCode: "ZIP CODE",
        activeModules: "Active Modules",
        actions: "Actions",
  
        devices: "Devices",
        manufacturer: "Manufacturer",
        model: "Model",
        reference: "Reference",
        hash: "Hash",
        hashIR: "IR Hash",
        createdAt: "Created at",
        alarm: "Alarms",
        lastComm: "Last comm.",
        commInterval: "Refresh time",
        latitude: "Latitude",
        tooltipLatitude: "Consulte a latitude, clicando com botão direito do mouse sobre um ponto de localização dentro do Google Maps.",
        longitude: "Longitude",
        tooltipLongitude: "Consulte a longitude, clicando com botão direito do mouse sobre um ponto de localização dentro do Google Maps.",

        noProject: "No projects registered",
        noDevices: "No devices registered",
  
        // NOTE: actions
        search: "Search",
        addProject: "Add Project",
        editProject: "Edit Project",
        deleteProject: "Delete Project",
        manageUsers: "User Management",
        virtualVariables: "Virtual Variables",

        detailsProject: "Details",
        addressProject: "Address",
  
        addDevice: "Add Device",
        editDevice: "Edit Device",
        deleteDevice: "Delete Device",
        manageGains: "Gain Factors",
        defaultAlarms: "Default Alarms",

  
        // NOTE: forms
        addDeviceTitle: "Add Device to Project",
        changeUsers: "Change Users of Project",
        manageGainsHead: "Manage Gain Factors of",
        defaultAlarmsHead: "Default Alarms of",
  
        select: "Select an option",

        gw: "Gateway",
        sd: "Standalone",
        pp: "Peripherical",
        lw: "Lora",
  
        addInBatches: "Add In Batch",
        automaticLoraRegisters: "Automatic Lora Registers",
  
        disabledPPText:
          "At least one Gateway is required to add a Peripherical device",
  
        new: "New",
        changeText: "Please select the users to be linked to the project",
  
        serialNumber: "Serial Number",
        gatewayId: "Link to Gateway",
  
        seconds: "Seconds",
        minutes: "Minutes",
        hours: "Hours",
  
        variable: "Variable",
        value: "Gain",
        noGains: "No gain factor registered for this device",
        edit: "Edit",
        delete: "Delete",
  
        image: "Image",
        imageText:
          "For best results, use an image at least 100px by 100px in .jpg format",
  
        newGatewayText:
          "Please select a new Gateway to link the peripherical devices currently linked to",
  
        // NOTE: toastrs
        att: "Attention",
        addGainWarning: "A variable and a gain factor are needed",
        editGainWarning: "A gain factor is needed",
        captchaLabel:
          'This action is irreversible. If you wish to continue, please type "DELETE" in the field below',
        captcha: "Wrong captcha",
        invalidText: "Some required fields are empty",
        insertIRHash: "Please insert the provided IR Hash",
        newGateway:
          "Is is required to choose a Gateway to link the periphericals",
  
        error: "Error",
        addGainError: "Add gain error. Please try again",
        editGainError: "Edit gain error. Please try again",
        removeGainError: "Remove gain error. Please try again",
  
        success: "Success",
        projectUpdated: "Project updated",
  
        // NOTE: buttons
        cancel: "Cancel",
        saveContinue: "Save and Keep Adding",
        saveProj: "Save Project",
        saveChanges: "Save Changes",
        saveDelete: "Delete Project",
        saveDevice: "Save Device",
        close: "Close",
        addUnit: "Add Unit",

  
        // NOTE: modules
        modules: "Modules",
        solarModule: "Solar Module",
        powerModule: "Power Module",
        waterModule: "Water Module",
        gasModule: "Gas Module",

        solarModuleOn: "Solar Module",
        powerModuleOn: "Power Module",
        waterModuleOn: "Water Module",
        gasModuleOn: "Gas Module",
        climateModuleOn: "Climate Module",
        nobreakModuleOn: "Nobreak Module",

        sending: "Sending",
        editModules: "Edit Modules",
    },
    devices: {
      title: "Devices of",
      selectData: "Please choose a data variable",
      lastComm: "Last communication",
      noData: "No data found",
      noDevices: "No device found",
      noDevicesTypes: "No device to filter",
      noVarData: "No data found for the selected data",
      noProject: "Project not found",
      offline: "Device offline",
      noComm: "Never had communicated",
      searchDevices: "Search devices",
      back: "Back to Projects",
      filter: "Filter",
      inverter: "Inverter",
      tracker: "Tracker",
      solarimeter: "Solarimeter",
      nobreak: "UPS",
      climate: "Climatization",
      utils: "Utilities",
      gateway: "Gateway",
      variablesOf: "Variables of",
      modalLastComm: "Last Publication",
      modalNoStatus: "No Status",
      modalNoPowerFactory: "No Factor",
      inputCurrent: "Input Current",
      inputVoltage: "Input Voltage",
      outputCurrent: "Output Current",
      outputVoltage: "Output Voltage",
      batery: "Batery",
      others: "Others",
      dropDownStatistic: "Statistic",
      dropDownGauge: "Gauge",
      dropDownState: "Status",
      status: {
        offline: "Offline",
        online: "Online",
        noComm: "Never communicated",
        partial: "Partially online",
      },
    },
}

export default enUsProjectTranslations;