import {
  faCheck,
  faPen,
  faPlus,
  faQuestionCircle,
  faSearch,
  faSort,
  faSortDown,
  faSortUp,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Button as AntButton,
  Form as AntForm,
  Input as AntInput,
  Select as AntSelect,
  Tabs as AntTabs,
  Badge,
  Card as CardAntd,
  DatePicker,
  List,
  Popconfirm,
  Space,
  Switch,
  Table as TableAntd,
} from "antd";
import React, { useEffect, useImperativeHandle, useRef, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Spinner,
  Tooltip,
  UncontrolledTooltip,
} from "reactstrap";

// import { Plus } from "react-feather";
import { useQueries, useQuery, useQueryClient } from "@tanstack/react-query";
import { Collapse, Upload } from "antd";
import ImgCrop from "antd-img-crop";
import axios from "axios";
import moment from "moment";
import BootstrapTable from "react-bootstrap-table-next";
import { useTranslation } from "react-i18next";
import { BiInfoCircle } from "react-icons/bi";
import { FiRefreshCcw } from "react-icons/fi";
import Resizer from "react-image-file-resizer";
import { toastr } from "react-redux-toastr";
import "./css/modal-img-crop.css";

/** MODAL DE EDIÇÃO DE REGISTRO DE PROJETO */
const ModalEditProject = ({ open, toggle, proj, setLoadProjects }) => {
  const { t } = useTranslation();
  const [avatarPreview, setAvatarPreview] = useState(proj.image);

  const [fileList, setFileList] = useState(
    avatarPreview !== "null"
      ? [
          {
            uid: "1",
            name: "image.JPEG",
            status: "done",
            url: avatarPreview,
          },
        ]
      : []
  );
  const onPreview = async (file) => {
    let src = file.url;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow.document.write(image.outerHTML);
  };
  /* Função redução da imagem e conversão para base64 */
  function resizeFile(file) {
    return new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        128,
        128,
        "JPEG",
        50,
        0,
        (uri) => {
          resolve(uri);
        },
        "base64"
      );
    });
  }

  const [isLoading, setLoading] = useState(true);
  const [comps, setComps] = useState([]);
  const [formComp, setFormComp] = useState(proj.companyId);
  const [formName, setFormName] = useState(proj.name);
  const [formDesc, setFormDesc] = useState(proj.description);
  const [formAddress, setFormAddress] = useState(proj.address);
  const [formNeighborhood, setFormNeighborhood] = useState(proj.neighborhood);
  const [formCity, setFormCity] = useState(proj.city);
  const [formState, setFormState] = useState(proj.state);
  const [formZipCode, setFormZipCode] = useState(proj.zipCode);

  const [validateFields, setValidateFields] = useState([
    {
      path: null,
      msg: null,
    },
  ]);

  function resetValidateFields(path) {
    const resetValidateFields = validateFields.filter((item) => {
      return item.path !== path;
    });
    setValidateFields(resetValidateFields);
  }

  /* Função upload de imagem */
  async function handleChange(e) {
    try {
      // console.log(event);
      const fileList = e.file.originFileObj; // busca imagem
      // console.log(fileList);
      const image = await resizeFile(fileList); // reduz e converte para base64
      console.log(image);
      // console.log('image', image);
      setAvatarPreview(image); // atualiza preview da imagem
      setFileList([e.file]);
      //const file = e.target.files[0];
    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
    axios
      .get("/companySearch")
      .then((res) => {
        setComps(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        // t() da problema com o useEffect
        toastr.error("Erro!", "Erro ao buscar empresas.");
      });
  }, []);

  return (
    <Modal isOpen={open} toggle={toggle} style={{ zIndex: 0 }}>
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          /* Salvar dados do formulário no formato JSON */
          const jsonData = JSON.stringify({
            projId: proj.id,
            currCompId: proj.companyId,
            companyId: formComp,
            name: formName,
            description: formDesc,
            address: formAddress,
            neighborhood: formNeighborhood,
            city: formCity,
            state: formState,
            zipCode: formZipCode,
            image: avatarPreview ? avatarPreview : "",
          });

          /*** Envio de dados ***/
          axios
            .put("/v2/project/updateProject", jsonData)
            .then((response) => {
              toastr.success(response.data.message);
              setLoadProjects(true); // Atualiza página
              toggle(); // Fecha o modal
            })
            .catch((error) => {
              if (error.response) {
                if (error.response.status === 400) {
                  setValidateFields(error.response.data.errors);
                }
              }
            });
        }}
      >
        <ModalHeader toggle={toggle}>
          {t("projTable.editProject")} {proj.name}
        </ModalHeader>

        <ModalBody className="text-left mx-2 p-4">
          <Card>
            <CardHeader
              style={{ fontSize: "20px" }}
              className="font-weight-bold"
            >
              {t("projTable.detailsProject")}
            </CardHeader>

            <CardBody>
              {/*** CAMPO DE EMPRESA ***/}
              {isLoading || !comps.length ? (
                <div className="text-center">
                  <Spinner color="primary" />
                </div>
              ) : (
                <FormGroup>
                  <Label>{t("projTable.company")}</Label>
                  <Input
                    type="select"
                    name="companyId"
                    id="companyId"
                    value={formComp}
                    onChange={(e) => setFormComp(e.target.value)}
                    onFocus={(e) => {
                      resetValidateFields("companyId");
                    }}
                  >
                    {comps.map((item, i) => {
                      return (
                        <option value={item.id} key={i}>
                          {item.name}
                        </option>
                      );
                    })}
                    {validateFields.map((item, index) => {
                      return item.path === "companyId" ? (
                        <small key={index} className="text-danger d-block">
                          {item.msg}
                        </small>
                      ) : null;
                    })}
                  </Input>
                </FormGroup>
              )}

              <Row>
                {/* Dados de projeto */}
                <Col md={8}>
                  {/*** CAMPO DE NOME ***/}
                  <FormGroup>
                    <Label>{t("projTable.name")}</Label>
                    <Input
                      type="text"
                      name="name"
                      id="name"
                      value={formName}
                      onChange={(e) => setFormName(e.target.value)}
                      onFocus={(e) => {
                        resetValidateFields("name");
                      }}
                    />
                    {validateFields.map((item, index) => {
                      return item.path === "name" ? (
                        <small key={index} className="text-danger d-block">
                          {item.msg}
                        </small>
                      ) : null;
                    })}
                  </FormGroup>

                  {/*** CAMPO DE DESCRIÇÃO ***/}
                  <FormGroup>
                    <Label>{t("projTable.description")}</Label>
                    <Input
                      type="textarea"
                      name="description"
                      id="description"
                      value={formDesc}
                      onChange={(e) => setFormDesc(e.target.value)}
                      rows="3"
                      onFocus={(e) => {
                        resetValidateFields("description");
                      }}
                    />
                    {validateFields.map((item, index) => {
                      return item.path === "description" ? (
                        <small key={index} className="text-danger d-block">
                          {item.msg}
                        </small>
                      ) : null;
                    })}
                  </FormGroup>
                </Col>
              </Row>
              {/* Imagem */}

              <Label>{t("projTable.image")}</Label>
              <Row>
                <Col>
                  <ImgCrop
                    rotate
                    modalProps={{
                      zIndex: 10000,
                    }}
                  >
                    <Upload
                      // action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                      listType="picture-card"
                      fileList={fileList}
                      onChange={(e) => {
                        handleChange(e);
                      }}
                      onPreview={onPreview}
                      onRemove={() => setAvatarPreview([])}
                    >
                      {fileList.length < 2 && "+ Upload"}
                    </Upload>
                  </ImgCrop>
                </Col>
                <Col>
                  <div>
                    <small>{t("settings.avatarText")}</small>
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>

          <Card>
            <CardHeader
              style={{ fontSize: "20px" }}
              className="font-weight-bold"
            >
              {t("projTable.addressProject")}
            </CardHeader>

            <CardBody>
              {/*** ENDEREÇO ***/}
              <FormGroup className="mt-4">
                <Label>{t("projTable.address")}</Label>
                <Input
                  type="text"
                  name="address"
                  id="address"
                  value={formAddress}
                  maxLength={150}
                  onChange={(e) => setFormAddress(e.target.value)}
                  onFocus={(e) => {
                    resetValidateFields("address");
                  }}
                />
                {validateFields.map((item, index) => {
                  return item.path === "address" ? (
                    <small key={index} className="text-danger d-block">
                      {item.msg}
                    </small>
                  ) : null;
                })}
              </FormGroup>

              <Row>
                <Col md={5}>
                  {/*** BAIRRO ***/}
                  <FormGroup>
                    <Label>{t("projTable.neighborhood")}</Label>
                    <Input
                      type="text"
                      name="neighborhood"
                      id="neighborhood"
                      value={formNeighborhood}
                      maxLength={100}
                      onChange={(e) => setFormNeighborhood(e.target.value)}
                      onFocus={(e) => {
                        resetValidateFields("neighborhood");
                      }}
                    />
                    {validateFields.map((item, index) => {
                      return item.path === "neighborhood" ? (
                        <small key={index} className="text-danger d-block">
                          {item.msg}
                        </small>
                      ) : null;
                    })}
                  </FormGroup>
                </Col>

                <Col md={5}>
                  {/*** CIDADE ***/}
                  <FormGroup>
                    <Label>{t("projTable.city")}</Label>
                    <Input
                      type="text"
                      name="city"
                      id="city"
                      value={formCity}
                      maxLength={100}
                      onChange={(e) => setFormCity(e.target.value)}
                      onFocus={(e) => {
                        resetValidateFields("city");
                      }}
                    />
                    {validateFields.map((item, index) => {
                      return item.path === "city" ? (
                        <small key={index} className="text-danger d-block">
                          {item.msg}
                        </small>
                      ) : null;
                    })}
                  </FormGroup>
                </Col>

                <Col md={2}>
                  {/*** ESTADO ***/}
                  <FormGroup>
                    <Label>{t("projTable.state")}</Label>
                    <Input
                      type="text"
                      name="state"
                      id="state"
                      value={formState}
                      maxLength={2}
                      onChange={(e) =>
                        setFormState(e.target.value.toUpperCase())
                      }
                      onFocus={(e) => {
                        resetValidateFields("state");
                      }}
                    />
                    {validateFields.map((item, index) => {
                      return item.path === "state" ? (
                        <small key={index} className="text-danger d-block">
                          {item.msg}
                        </small>
                      ) : null;
                    })}
                  </FormGroup>
                </Col>
              </Row>

              <Row>
                <Col md={6}>
                  {/*** CEP ***/}
                  <FormGroup>
                    <Label>{t("projTable.zipCode")}</Label>
                    <Input
                      type="text"
                      name="zipCode"
                      id="zipCode"
                      value={formZipCode}
                      maxLength={15}
                      onChange={(e) => setFormZipCode(e.target.value)}
                      onFocus={(e) => {
                        resetValidateFields("zipCode");
                      }}
                    />
                    {validateFields.map((item, index) => {
                      return item.path === "zipCode" ? (
                        <small key={index} className="text-danger d-block">
                          {item.msg}
                        </small>
                      ) : null;
                    })}
                  </FormGroup>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </ModalBody>

        <ModalFooter>
          {/*** BOTÃO DE CANCELAR *
           * (apenas fecha o modal sem enviar nada para a API) ***/}
          <Button
            name="btnCancel"
            color="primary"
            className="mr-1 mb-1"
            onClick={toggle}
            outline
          >
            {t("projTable.cancel")}
          </Button>

          {/*** BOTÃO DE ENVIAR DADOS E FECHAR O MODAL ***/}
          <Button name="btnClose" type="submit" color="primary">
            {t("projTable.saveChanges")}
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

/** MODAL DE EDIÇÃO DE REGISTRO DE PERIFÉRICO */
const ModalEditPP = ({ toggle, dvc, setLoadDevices, deviceList }) => {
  const { t } = useTranslation();

  const [manufacturers, setManufacturers] = useState([]); // lista de fabricantes
  const [formManuf, setFormManuf] = useState(dvc.manufacturer); // fabricante selecionado
  const [models, setModels] = useState([]); // lista de modelos
  const [formModel, setFormModel] = useState(dvc.model); // modelo selecionado
  const [formDesc, setFormDesc] = useState(dvc.description); // descricao
  const [invalidManuf, setInvalidManuf] = useState(false); // estado de fabricante invalido
  const [invalidModel, setInvalidModel] = useState(false); // estado de modelo invalido
  const [invalidDesc, setInvalidDesc] = useState(false); // estado de descrição invalido
  const [formInterval, setFormInterval] = useState(dvc.commInterval); // tempo de atualização
  const [formLatitude, setFormLatitude] = useState(dvc.latitude); // latitude
  const [formLongitude, setFormLongitude] = useState(dvc.longitude); // longitude
  const [invalidInterval, setInvalidInterval] = useState(false); // estado de tempo invalido
  const [intUnit, setIntUnit] = useState(1); // unidade de tempo de indicação (s, min, h)
  const [formGW, setFormGW] = useState(dvc.gatewayId); // gateway do dispositivo
  // const [invalidGW, setInvalidGW] = useState(false); // estado de GW invalido

  const [tooltipLatitudeOpen, setTooltipLatitudeOpen] = useState(false);
  const [tooltipLongitudeOpen, setTooltipLongitudeOpen] = useState(false);
  const toggleTooltipLatitude = () =>
    setTooltipLatitudeOpen(!tooltipLatitudeOpen);
  const toggleTooltipLongitude = () =>
    setTooltipLongitudeOpen(!tooltipLongitudeOpen);

  const [loading, setLoading] = useState(false); // estado de carregamento envio de dados para api

  const gwList = deviceList.filter((item) => item.category === "gw");

  // busca lista de fabricantes
  useEffect(() => {
    axios
      .get("deviceManufacturerSearch/")
      .then((res) => {
        setManufacturers(res.data);
      })
      .catch((err) => {
        console.log(err);
        // t() da problema com o useEffect
        toastr.error("Erro!", "Erro ao buscar fabricantes.");
      });
  }, []);

  // busca lista de modelos do fabricante selecionado
  useEffect(() => {
    if (formManuf) {
      axios
        .get("/deviceModelSearch", {
          params: {
            name: formManuf,
          },
        })
        .then((res) => {
          // console.log(res, "RES");
          setModels(res.data);
        })
        .catch((err) => {
          console.log(err);
          // t() da problema com o useEffect
          toastr.warning("Erro!", "Erro ao buscar modelos.");
        });
    }
  }, [formManuf]);

  function onSubmitForm() {
    if (!formManuf || !formModel || !formDesc || !formInterval) {
      setInvalidManuf(!formManuf);
      setInvalidModel(!formModel);
      setInvalidDesc(!formDesc);
      setInvalidInterval(!formInterval);
      toastr.warning(
        `${t("projTable.att")}!`,
        `${t("projTable.invalidText")}.`
      );
    } else {
      /* Salvar dados do formulário no formato JSON */
      const jsonData = {
        deviceId: dvc.id,
        gatewayId: formGW,
        manufacturer: formManuf,
        model: formModel,
        description: formDesc,
        commInterval: formInterval * intUnit,
        latitude: formLatitude,
        longitude: formLongitude,
      };

      setLoading(true);
      /*** Envio de dados ***/
      axios
        .put("editDevice/", JSON.stringify(jsonData))
        .then((res) => {
          setLoading(false);
          toastr.success(res.data.messageHead, res.data.messageBody);
          setLoadDevices(true); // Atualiza tabela
          toggle(); // fechar o modal
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
          console.log(err.response);

          if (err.response) {
            /* Notificação de erro de envio */
            toastr.warning(
              err.response.data.messageHead,
              err.response.data.messageBody
            );
          }
        });
    }
  }

  return (
    <Form
      onSubmit={(e) => {
        e.preventDefault();
        onSubmitForm();
      }}
    >
      <ModalBody className="text-left mx-6">
        <h5 className="text-center">Editar Periférico</h5>

        <hr />

        {/*** CAMPO DE FABRICANTE ***/}
        <FormGroup>
          <Label>{t("projTable.manufacturer")}</Label>
          <Input
            type="select"
            name="manufacturer"
            id="manufacturer"
            value={formManuf}
            onChange={(e) => {
              setFormManuf(e.target.value);
              setFormModel("");
              setInvalidManuf(false);
              setInvalidModel(false);
            }}
            // disabled={(!manufacturers.length)}
            invalid={invalidManuf}
            // disabled={!manufacturers.length || loading}
            disabled
          >
            <option value="" disabled>
              {t("projTable.select")}
            </option>
            {manufacturers.map((item, i) => (
              <option value={item} key={i}>
                {item}
              </option>
            ))}
            <option value="myDrivers">Meus Drivers</option>
          </Input>
        </FormGroup>

        {/* CAMPO DE MODELO */}
        <FormGroup>
          <Label>{t("projTable.model")}</Label>
          <Input
            type="select"
            name="model"
            id="model"
            value={formModel}
            onChange={(e) => {
              setFormModel(e.target.value);
              setInvalidModel(false);
            }}
            // disabled={(!models.length)}
            invalid={invalidModel}
            // disabled={!models.length || loading}
            disabled
          >
            <option value="" disabled>
              {t("projTable.select")}
            </option>
            {models.map((item, i) => (
              <option value={item.model} key={i}>
                {item.model}
              </option>
            ))}
          </Input>
        </FormGroup>

        {/* CAMPO DE DESCRIÇÃO */}
        <FormGroup>
          <Label>{t("projTable.reference")}</Label>
          <Input
            type="text"
            name="description"
            id="description"
            value={formDesc}
            onChange={(e) => {
              setFormDesc(e.target.value);
              setInvalidDesc(false);
            }}
            invalid={invalidDesc}
            disabled={loading}
          />
        </FormGroup>

        {/* CAMPO DE GATEWAY DO DISPOSITIVO */}
        {gwList.length ? (
          <FormGroup>
            <Label>{t("projTable.gatewayId")}</Label>
            <Input
              type="select"
              name="deviceGW"
              id="deviceGW"
              value={formGW}
              onChange={(e) => {
                setFormGW(e.target.value);
                // setInvalidGW(false);
              }}
              // invalid={invalidGW}
              // disabled={!gwList.length}
              disabled={loading}
            >
              <option value="" disabled>
                {t("projTable.select")}
              </option>
              {gwList.map((item, i) => (
                <option value={item.id} key={i}>
                  {item.description}
                </option>
              ))}
            </Input>
          </FormGroup>
        ) : null}

        {/*** CAMPO DE TEMPO DE ATUALIZAÇÃO ***/}
        <FormGroup>
          <Label>{t("projTable.commInterval")}</Label>
          <Row>
            <Col md={4}>
              <Input
                type="number"
                name="commInterval"
                id="commInterval"
                value={formInterval}
                onChange={(e) => {
                  setFormInterval(e.target.value);
                  setInvalidInterval(false);
                }}
                invalid={invalidInterval}
                disabled={loading}
              />
            </Col>

            <Col md={4}>
              <Input
                className="pt-0"
                type="select"
                name="intUnit"
                id="intUnit"
                value={intUnit}
                onChange={(e) => setIntUnit(e.target.value)}
                disabled={loading}
              >
                <option value={1}>{t("projTable.seconds")}</option>
                <option value={60}>{t("projTable.minutes")}</option>
                <option value={3600}>{t("projTable.hours")}</option>
              </Input>
            </Col>
          </Row>
        </FormGroup>

        <FormGroup>
          <Row>
            {/* LATITUDE */}
            <Col md={6}>
              <Label>
                {t("projTable.latitude")}
                <FontAwesomeIcon
                  id="latitudeHelpIcon"
                  fixedWidth
                  icon={faQuestionCircle}
                />
                <Tooltip
                  isOpen={tooltipLatitudeOpen}
                  target="latitudeHelpIcon"
                  toggle={toggleTooltipLatitude}
                >
                  {t("projTable.tooltipLatitude")}
                </Tooltip>
              </Label>
              <Input
                type="text"
                name="latitude"
                value={formLatitude}
                onChange={(e) => {
                  setFormLatitude(e.target.value);
                }}
                disabled={loading}
              />
            </Col>

            {/* LONGITUDE */}
            <Col md={6}>
              <Label>
                {t("projTable.longitude")}
                <FontAwesomeIcon
                  id="longitudeHelpIcon"
                  fixedWidth
                  icon={faQuestionCircle}
                />
                <Tooltip
                  isOpen={tooltipLongitudeOpen}
                  target="longitudeHelpIcon"
                  toggle={toggleTooltipLongitude}
                >
                  {t("projTable.tooltipLongitude")}
                </Tooltip>
              </Label>
              <Input
                type="text"
                name="longitude"
                value={formLongitude}
                onChange={(e) => {
                  setFormLongitude(e.target.value);
                }}
                disabled={loading}
              />
            </Col>
          </Row>
        </FormGroup>
      </ModalBody>

      <ModalFooter>
        {loading ? (
          <Spinner color="primary" className="mr-3" />
        ) : (
          <>
            {/** BOTÃO DE CANCELAR */}
            <Button
              type="button"
              color="primary"
              className="mr-1"
              onClick={toggle}
              outline
            >
              {t("projTable.cancel")}
            </Button>

            {/** BOTÃO DE ENVIAR DADOS */}
            <Button name="btnClose" type="submit" color="primary">
              {t("projTable.saveChanges")}
            </Button>
          </>
        )}
      </ModalFooter>
    </Form>
  );
};

/** MODAL DE EDIÇÃO DE REGISTRO DE GATEWAY */
const ModalEditGW = ({ toggle, dvc, setLoadDevices }) => {
  const { t } = useTranslation();

  const [manufacturers, setManufacturers] = useState([]); // lista de fabricantes
  const [models, setModels] = useState([]); // lista de modelos

  const [formManuf, setFormManuf] = useState(dvc.manufacturer); // fabricante selecionado
  const [formModel, setFormModel] = useState(dvc.model); // modelo selecionado
  const [formSN, setFormSN] = useState(dvc.serialNumber); // serial number
  const [formDesc, setFormDesc] = useState(dvc.description); // descrição
  const [formInterval, setFormInterval] = useState(dvc.commInterval); // tempo de atualização
  const [formLatitude, setFormLatitude] = useState(dvc.latitude); // latitude
  const [formLongitude, setFormLongitude] = useState(dvc.longitude); // longitude
  const [intUnit, setIntUnit] = useState(1); // unidade de tempo de indicação (s, min, h)

  const [tooltipLatitudeOpen, setTooltipLatitudeOpen] = useState(false);
  const [tooltipLongitudeOpen, setTooltipLongitudeOpen] = useState(false);
  const toggleTooltipLatitude = () =>
    setTooltipLatitudeOpen(!tooltipLatitudeOpen);
  const toggleTooltipLongitude = () =>
    setTooltipLongitudeOpen(!tooltipLongitudeOpen);

  const [invalidManuf, setInvalidManuf] = useState(false); // estado de fabricante invalido
  const [invalidModel, setInvalidModel] = useState(false); // estado de modelo invalido
  const [invalidSN, setInvalidSN] = useState(false); // estado de serial number invalido
  const [invalidDesc, setInvalidDesc] = useState(false); // estado de descrição invalido
  const [invalidInterval, setInvalidInterval] = useState(false); // estado de tempo invalido

  const [loading, setLoading] = useState(false); // estado de carregamento envio de dados para api

  // busca lista de fabricantes de gateway
  useEffect(() => {
    axios
      .get("gwManufacturerSearch/")
      .then((res) => {
        setManufacturers(res.data);
      })
      .catch((err) => {
        console.log(err);
        // t() da problema com o useEffect
        toastr.error("Erro!", "Erro ao buscar fabricantes.");
      });
  }, []);

  // busca lista de modelos do fabricante selecionado
  useEffect(() => {
    if (formManuf) {
      axios
        .get("/gwModelSearch", {
          params: {
            name: formManuf,
          },
        })
        .then((res) => {
          setModels(res.data);
        })
        .catch((err) => {
          console.log(err);
          // t() da problema com o useEffect
          toastr.warning("Erro!", "Erro ao buscar modelos.");
        });
    }
  }, [formManuf]);

  function onSubmitForm() {
    if (!formManuf || !formModel || !formSN || !formDesc || !formInterval) {
      setInvalidManuf(!formManuf);
      setInvalidModel(!formModel);
      setInvalidSN(!formSN);
      setInvalidDesc(!formDesc);
      setInvalidInterval(!formInterval);
      toastr.warning(
        `${t("projTable.att")}!`,
        `${t("projTable.invalidText")}.`
      );
    } else {
      /* Salvar dados do formulário no formato JSON */
      const jsonData = {
        deviceId: dvc.id,
        manufacturer: formManuf,
        model: formModel,
        serialNumber: formSN,
        description: formDesc,
        commInterval: formInterval * intUnit,
        latitude: formLatitude,
        longitude: formLongitude,
      };

      setLoading(true);
      /*** Envio de dados ***/
      axios
        .put("editGateway/", JSON.stringify(jsonData))
        .then((res) => {
          setLoading(false);
          toastr.success(res.data.messageHead, res.data.messageBody);
          setLoadDevices(true); // Atualiza tabela
          toggle(); // ...fechar o modal
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
          console.log(err.response);

          if (err.response) {
            /* Notificação de erro de envio */
            toastr.warning(
              err.response.data.messageHead,
              err.response.data.messageBody
            );
          }
        });
    }
  }

  return (
    <Form
      onSubmit={(e) => {
        e.preventDefault();

        onSubmitForm();
      }}
    >
      <ModalBody className="text-left mx-6">
        <h5 className="text-center">Editar Gateway</h5>

        <hr />

        {/*** CAMPO DE FABRICANTE ***/}
        <FormGroup>
          <Label>{t("projTable.manufacturer")}</Label>
          <Input
            type="select"
            name="manufacturer"
            id="manufacturer"
            value={formManuf}
            onChange={(e) => {
              setFormManuf(e.target.value);
              setFormModel("");
              setInvalidManuf(false);
              setInvalidModel(false);
            }}
            invalid={invalidManuf}
            // disabled={!manufacturers.length || loading}
            disabled
          >
            <option value="" disabled>
              {t("projTable.select")}
            </option>
            {manufacturers.map((item, i) => (
              <option value={item} key={i}>
                {item}
              </option>
            ))}
            <option value="myDrivers">Meus Drivers</option>
          </Input>
        </FormGroup>

        {/* CAMPO DE MODELO */}
        <FormGroup>
          <Label>{t("projTable.model")}</Label>
          <Input
            type="select"
            name="model"
            id="model"
            // disabled={(!models.length)}
            value={formModel}
            onChange={(e) => {
              setFormModel(e.target.value);
              setInvalidModel(false);
            }}
            invalid={invalidModel}
            // disabled={!models.length || loading}
            disabled
          >
            <option value="" disabled>
              {t("projTable.select")}
            </option>
            {models.map((item, i) => (
              <option value={item.model} key={i}>
                {item.model}
              </option>
            ))}
          </Input>
        </FormGroup>

        {/* CAMPO DE SERIAL NUMBER */}
        <FormGroup>
          <Label>{t("projTable.serialNumber")}</Label>
          <Input
            type="text"
            name="serialNumber"
            id="serialNumber"
            value={formSN}
            onChange={(e) => {
              setFormSN(e.target.value);
              setInvalidSN(false);
            }}
            invalid={invalidSN}
            // disabled={loading}
            disabled
          />
        </FormGroup>

        {/* CAMPO DE DESCRIÇÃO/REFERÊNCIA */}
        <FormGroup>
          <Label>{t("projTable.reference")}</Label>
          <Input
            type="text"
            name="description"
            id="description"
            value={formDesc}
            onChange={(e) => {
              setFormDesc(e.target.value);
              setInvalidDesc(false);
            }}
            invalid={invalidDesc}
            disabled={loading}
          />
        </FormGroup>

        {/* CAMPO DE TEMPO DE ATUALIZAÇÃO */}
        <FormGroup>
          <Label>{t("projTable.commInterval")}</Label>
          <Row>
            <Col md={4}>
              <Input
                type="number"
                name="commInterval"
                id="commInterval"
                value={formInterval}
                onChange={(e) => {
                  setFormInterval(e.target.value);
                  setInvalidInterval(false);
                }}
                invalid={invalidInterval}
                disabled={loading}
              />
            </Col>

            <Col md={4}>
              <Input
                className="pt-0"
                type="select"
                name="intUnit"
                id="intUnit"
                value={intUnit}
                onChange={(e) => setIntUnit(e.target.value)}
                disabled={loading}
              >
                <option value={1}>{t("projTable.seconds")}</option>
                <option value={60}>{t("projTable.minutes")}</option>
                <option value={3600}>{t("projTable.hours")}</option>
              </Input>
            </Col>
          </Row>
        </FormGroup>

        <FormGroup>
          <Row>
            {/* LATITUDE */}
            <Col md={6}>
              <Label>
                {t("projTable.latitude")}
                <FontAwesomeIcon
                  id="latitudeHelpIcon"
                  fixedWidth
                  icon={faQuestionCircle}
                />
                <Tooltip
                  isOpen={tooltipLatitudeOpen}
                  target="latitudeHelpIcon"
                  toggle={toggleTooltipLatitude}
                >
                  {t("projTable.tooltipLatitude")}
                </Tooltip>
              </Label>
              <Input
                type="text"
                name="latitude"
                value={formLatitude}
                onChange={(e) => {
                  setFormLatitude(e.target.value);
                }}
                disabled={loading}
              />
            </Col>

            {/* LONGITUDE */}
            <Col md={6}>
              <Label>
                {t("projTable.longitude")}
                <FontAwesomeIcon
                  id="longitudeHelpIcon"
                  fixedWidth
                  icon={faQuestionCircle}
                />
                <Tooltip
                  isOpen={tooltipLongitudeOpen}
                  target="longitudeHelpIcon"
                  toggle={toggleTooltipLongitude}
                >
                  {t("projTable.tooltipLongitude")}
                </Tooltip>
              </Label>
              <Input
                type="text"
                name="longitude"
                value={formLongitude}
                onChange={(e) => {
                  setFormLongitude(e.target.value);
                }}
                disabled={loading}
              />
            </Col>
          </Row>
        </FormGroup>
      </ModalBody>

      <ModalFooter>
        {loading ? (
          <Spinner color="primary" className="mr-3" />
        ) : (
          <>
            {/** BOTÃO DE CANCELAR */}
            <Button
              type="button"
              color="primary"
              className="mr-1"
              onClick={toggle}
              outline
            >
              {t("projTable.cancel")}
            </Button>

            {/** BOTÃO DE ENVIAR DADOS */}
            <Button name="btnClose" type="submit" color="primary">
              {t("projTable.saveChanges")}
            </Button>
          </>
        )}
      </ModalFooter>
    </Form>
  );
};

/****** MODAL DE NOVO STANDALONE ******/
const ModalEditSD = ({ toggle, dvc, setLoadDevices }) => {
  const { t } = useTranslation();

  const [manufacturers, setManufacturers] = useState([]); // lista de fabricantes
  const [models, setModels] = useState([]); // lista de modelos
  const [acList, setACList] = useState([]); // lista de aparelhos de a/c cadastrados

  const [formManuf, setFormManuf] = useState(dvc.manufacturer); // fabricante selecionado
  const [formModel, setFormModel] = useState(dvc.model); // modelo selecionado
  const [formIR, setFormIR] = useState(dvc.infraredId); // hash de IR
  const [formHash, setFormHash] = useState(dvc.hash); // hash de dispositivo
  const [formDesc, setFormDesc] = useState(dvc.description); // descrição
  const [formInterval, setFormInterval] = useState(dvc.commInterval); // tempo de atualização
  const [formLatitude, setFormLatitude] = useState(dvc.latitude); // latitude
  const [formLongitude, setFormLongitude] = useState(dvc.longitude); // longitude
  const [intUnit, setIntUnit] = useState(1); // unidade de tempo de indicação (s, min, h)

  const [tooltipLatitudeOpen, setTooltipLatitudeOpen] = useState(false);
  const [tooltipLongitudeOpen, setTooltipLongitudeOpen] = useState(false);
  const toggleTooltipLatitude = () =>
    setTooltipLatitudeOpen(!tooltipLatitudeOpen);
  const toggleTooltipLongitude = () =>
    setTooltipLongitudeOpen(!tooltipLongitudeOpen);

  const [climate, setClimate] = useState(false); // indicador de dispositivo de climatização
  const [loadClimate, setLoadClimate] = useState(false); // estado de carregamento lista de aparelhos de a/c cadastrados

  const [invalidManuf, setInvalidManuf] = useState(false); // estado de fabricante invalido
  const [invalidModel, setInvalidModel] = useState(false); // estado de modelo invalido
  const [invalidIR, setInvalidIR] = useState(false); // estado de hash de IR invalido
  const [invalidDesc, setInvalidDesc] = useState(false); // estado de descrição invalido
  const [invalidInterval, setInvalidInterval] = useState(false); // estado de tempo invalido

  const [loading, setLoading] = useState(false); // estado de carregamento envio de dados para api

  // busca lista de fabricantes de standalone
  useEffect(() => {
    axios
      .get("sdManufacturerSearch/")
      .then((res) => {
        setManufacturers(res.data);
      })
      .catch((err) => {
        console.log(err);
        // t() da problema com o useEffect
        toastr.error("Erro!", "Erro ao buscar fabricantes.");
      });
  }, []);

  // busca lista de modelos do fabricante selecionado
  useEffect(() => {
    if (formManuf) {
      axios
        .get("/sdModelSearch", {
          params: {
            name: formManuf,
          },
        })
        .then((res) => {
          setModels(res.data);
          if (
            res.data
              .find((item) => item.model === dvc.model)
              .types.includes("C")
          ) {
            setClimate(true);
            setLoadClimate(true);
          }
        })
        .catch((err) => {
          console.log(err);
          // t() da problema com o useEffect
          toastr.warning("Erro!", "Erro ao buscar modelos.");
        });
    }
  }, [formManuf, dvc]);

  // busca lista de dispositivos de ar condicionado
  useEffect(() => {
    if (loadClimate) {
      axios
        .get("/readACList", {
          params: {
            infraredId: formIR,
          },
        })
        .then((res) => {
          setACList(res.data.deviceList);
          setLoadClimate(false);
        })
        .catch((err) => {
          console.log(err);
          // t() da problema com o useEffect
          toastr.warning("Erro!", "Erro ao buscar dispositivos.");
          setInvalidIR(true);
          setLoadClimate(false);
        });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadClimate]);

  function onSubmitForm() {
    if (
      !formManuf ||
      !formModel ||
      !formDesc ||
      (climate && !formIR) ||
      (!climate && !formInterval)
    ) {
      setInvalidManuf(!formManuf);
      setInvalidModel(!formModel);
      setInvalidDesc(!formDesc);
      setInvalidIR(climate && !formIR);
      setInvalidInterval(!climate && !formInterval);
      toastr.warning(
        `${t("projTable.att")}!`,
        `${t("projTable.invalidText")}.`
      );
    } else {
      /* Salvar dados do formulário no formato JSON */
      const jsonData = {
        deviceId: dvc.id,
        climate: climate,
        manufacturer: formManuf,
        model: formModel,
        description: formDesc,
        deviceHash: formHash,
        infraredId: formIR,
        commInterval: formInterval * intUnit,
        latitude: formLatitude,
        longitude: formLongitude,
      };

      setLoading(true);
      /*** Envio de dados ***/
      axios
        .put("editStandalone/", JSON.stringify(jsonData))
        .then((res) => {
          setLoading(false);
          toastr.success(res.data.messageHead, res.data.messageBody);
          setLoadDevices(true); // Atualiza tabela
          toggle(); // ...fechar o modal
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
          console.log(err.response);

          if (err.response) {
            /* Notificação de erro de envio */
            toastr.warning(
              err.response.data.messageHead,
              err.response.data.messageBody
            );
          }
        });
    }
  }

  return (
    <Form
      onSubmit={(e) => {
        e.preventDefault();
        onSubmitForm();
      }}
    >
      <ModalBody className="text-left mx-6">
        <h5 className="text-center">Editar Standalone</h5>
        <hr />
        {/*** CAMPO DE FABRICANTE ***/}
        <FormGroup>
          <Label>{t("projTable.manufacturer")}</Label>
          <Input
            type="select"
            name="manufacturer"
            id="manufacturer"
            value={formManuf}
            onChange={(e) => {
              setFormManuf(e.target.value);
              setFormModel("");
              setClimate(false);
              setInvalidManuf(false);
              setInvalidModel(false);
              setInvalidDesc(false);
              setInvalidIR(false);
              setInvalidInterval(false);
            }}
            invalid={invalidManuf}
            // disabled={!manufacturers.length || loading}
            disabled
          >
            <option value="" disabled>
              {t("projTable.select")}
            </option>
            {manufacturers.map((item, i) => (
              <option value={item} key={i}>
                {item}
              </option>
            ))}
            <option value="myDrivers">Meus Drivers</option>
          </Input>
        </FormGroup>
        {/* CAMPO DE MODELO */}
        <FormGroup>
          <Label>{t("projTable.model")}</Label>
          <Input
            type="select"
            name="model"
            id="model"
            value={formModel}
            onChange={(e) => {
              setFormModel(e.target.value);
              setInvalidModel(false);
              setInvalidDesc(false);
              setInvalidIR(false);
              setInvalidInterval(false);

              if (
                models
                  .find((item) => item.model === e.target.value)
                  .types.includes("C")
              ) {
                setClimate(true);
                setFormInterval(60);
                setIntUnit(60);
              } else {
                setClimate(false);
                setFormHash("");
                setFormIR("");
              }
            }}
            // disabled={(!models.length)}
            invalid={invalidModel}
            // disabled={!models.length || loading}
            disabled
          >
            <option value="" disabled>
              {t("projTable.select")}
            </option>
            {models.map((item, i) => (
              <option value={item.model} key={i}>
                {item.model}
              </option>
            ))}
          </Input>
        </FormGroup>
        {/* CAMPO DE IR HASH */}
        <FormGroup>
          <Label>{t("projTable.hashIR")}</Label>
          <InputGroup className="mb-3" size="sm">
            <Input
              type="text"
              name="irHash"
              id="irHash"
              value={formIR}
              disabled={loadClimate || loading}
              onChange={(e) => {
                setFormIR(e.target.value);
                setFormDesc("");
                setInvalidIR(false);
                setACList([]);
              }}
              invalid={invalidIR}
            />
            <InputGroupAddon addonType="append">
              <Button
                color="primary"
                disabled={loadClimate}
                onClick={() => {
                  if (formIR === "") {
                    setInvalidIR(true);
                    toastr.warning(
                      `${t("projTable.att")}!`,
                      `${t("projTable.insertIRHash")}.`
                    );
                  } else {
                    setLoadClimate(true);
                    setInvalidDesc(false);
                  }
                }}
              >
                {loadClimate ? (
                  <div className="text-center">
                    <Spinner color="white" size="sm" />
                  </div>
                ) : (
                  <FontAwesomeIcon fixedWidth icon={faSearch} />
                )}
              </Button>
            </InputGroupAddon>
          </InputGroup>
        </FormGroup>
        {/* CAMPO DE DESCRIÇÃO */}
        <FormGroup>
          <Label>{t("projTable.reference")}</Label>
          <Input
            type="select"
            name="description"
            id="description"
            value={formDesc}
            onChange={(e) => {
              setFormDesc(e.target.value);
              setFormHash(
                acList.find((item) => item.deviceName === e.target.value)
                  .deviceHash
              );
              setInvalidDesc(false);
            }}
            disabled={!acList.length || loadClimate || loading}
            invalid={invalidDesc}
          >
            <option value="" disabled>
              {formDesc}
            </option>
            {acList.length
              ? acList.map((item, i) => (
                  <option value={item.deviceName} key={i}>
                    {item.deviceName}
                  </option>
                ))
              : null}
          </Input>
        </FormGroup>
        :{" "}
        <FormGroup>
          <Label>{t("projTable.reference")}</Label>
          <Input
            type="text"
            name="description"
            id="description"
            value={formDesc}
            onChange={(e) => {
              setFormDesc(e.target.value);
              setInvalidDesc(false);
            }}
            invalid={invalidDesc}
            disabled={loading}
          />
        </FormGroup>
        {/*** CAMPO DE TEMPO DE ATUALIZAÇÃO ***/}
        <FormGroup>
          <Label>{t("projTable.commInterval")}</Label>
          <Row>
            <Col md={4}>
              <Input
                type="number"
                name="commInterval"
                id="commInterval"
                value={formInterval}
                onChange={(e) => {
                  setFormInterval(e.target.value);
                  setInvalidInterval(false);
                }}
                invalid={invalidInterval}
                disabled={loading}
              />
            </Col>

            <Col md={4}>
              <Input
                className="pt-0"
                type="select"
                name="intUnit"
                id="intUnit"
                value={intUnit}
                onChange={(e) => setIntUnit(e.target.value)}
                disabled={loading}
              >
                <option value={1}>{t("projTable.seconds")}</option>
                <option value={60}>{t("projTable.minutes")}</option>
                <option value={3600}>{t("projTable.hours")}</option>
              </Input>
            </Col>
          </Row>
        </FormGroup>
        <FormGroup>
          <Row>
            {/* LATITUDE */}
            <Col md={6}>
              <Label>
                {t("projTable.latitude")}
                <FontAwesomeIcon
                  id="latitudeHelpIcon"
                  fixedWidth
                  icon={faQuestionCircle}
                />
                <Tooltip
                  isOpen={tooltipLatitudeOpen}
                  target="latitudeHelpIcon"
                  toggle={toggleTooltipLatitude}
                >
                  {t("projTable.tooltipLatitude")}
                </Tooltip>
              </Label>
              <Input
                type="text"
                name="latitude"
                value={formLatitude}
                onChange={(e) => {
                  setFormLatitude(e.target.value);
                }}
                disabled={loading}
              />
            </Col>

            {/* LONGITUDE */}
            <Col md={6}>
              <Label>
                {t("projTable.longitude")}
                <FontAwesomeIcon
                  id="longitudeHelpIcon"
                  fixedWidth
                  icon={faQuestionCircle}
                />
                <Tooltip
                  isOpen={tooltipLongitudeOpen}
                  target="longitudeHelpIcon"
                  toggle={toggleTooltipLongitude}
                >
                  {t("projTable.tooltipLongitude")}
                </Tooltip>
              </Label>
              <Input
                type="text"
                name="longitude"
                value={formLongitude}
                onChange={(e) => {
                  setFormLongitude(e.target.value);
                }}
                disabled={loading}
              />
            </Col>
          </Row>
        </FormGroup>
      </ModalBody>

      <ModalFooter>
        {loading ? (
          <Spinner color="primary" className="mr-3" />
        ) : (
          <>
            {/** BOTÃO DE CANCELAR */}
            <Button
              type="button"
              color="primary"
              className="mr-1"
              onClick={toggle}
              outline
            >
              {t("projTable.cancel")}
            </Button>

            {/** BOTÃO DE ENVIAR DADOS */}
            <Button name="btnClose" type="submit" color="primary">
              {t("projTable.saveChanges")}
            </Button>
          </>
        )}
      </ModalFooter>
    </Form>
  );
};

/****** MODAL DE NOVO LORAWAN ******/
const ModalEditLW = ({ toggle, dvc, setLoadDevices }) => {
  const { t } = useTranslation();

  const [hash, setHash] = useState(dvc.hash);
  const [networkHash, setNetworkHash] = useState(dvc.accessSecret);
  const [deviceAddress, setDeviceAddress] = useState(dvc.accessKey);

  const [manufacturers, setManufacturers] = useState([]); // lista de fabricantes
  const [models, setModels] = useState([]); // lista de modelos
  const [acList, setACList] = useState([]); // lista de aparelhos de a/c cadastrados

  const [formManuf, setFormManuf] = useState(dvc.manufacturer); // fabricante selecionado
  const [formModel, setFormModel] = useState(dvc.model); // modelo selecionado
  const [formIR, setFormIR] = useState(dvc.hash); // hash de IR
  const [formHash, setFormHash] = useState(dvc.hash); // hash de dispositivo
  const [formDesc, setFormDesc] = useState(dvc.description); // descrição
  const [formInterval, setFormInterval] = useState(dvc.commInterval); // tempo de atualização
  const [formLatitude, setFormLatitude] = useState(dvc.latitude); // latitude
  const [formLongitude, setFormLongitude] = useState(dvc.longitude); // longitude
  const [intUnit, setIntUnit] = useState(1); // unidade de tempo de indicação (s, min, h)

  const [tooltipLatitudeOpen, setTooltipLatitudeOpen] = useState(false);
  const [tooltipLongitudeOpen, setTooltipLongitudeOpen] = useState(false);
  const toggleTooltipLatitude = () =>
    setTooltipLatitudeOpen(!tooltipLatitudeOpen);
  const toggleTooltipLongitude = () =>
    setTooltipLongitudeOpen(!tooltipLongitudeOpen);

  const [climate, setClimate] = useState(false); // indicador de dispositivo de climatização
  const [loadClimate, setLoadClimate] = useState(false); // estado de carregamento lista de aparelhos de a/c cadastrados

  const [invalidManuf, setInvalidManuf] = useState(false); // estado de fabricante invalido
  const [invalidModel, setInvalidModel] = useState(false); // estado de modelo invalido
  const [invalidIR, setInvalidIR] = useState(false); // estado de hash de IR invalido
  const [invalidDesc, setInvalidDesc] = useState(false); // estado de descrição invalido
  const [invalidInterval, setInvalidInterval] = useState(false); // estado de tempo invalido

  const [loading, setLoading] = useState(false); // estado de carregamento envio de dados para api

  // busca lista de fabricantes de standalone
  useEffect(() => {
    axios
      .get("lwManufacturerSearch/")
      .then((res) => {
        setManufacturers(res.data);
      })
      .catch((err) => {
        console.log(err);
        // t() da problema com o useEffect
        toastr.error("Erro!", "Erro ao buscar fabricantes.");
      });
  }, []);

  // busca lista de modelos do fabricante selecionado
  useEffect(() => {
    if (formManuf) {
      axios
        .get("/lwModelSearch", {
          params: {
            name: formManuf,
          },
        })
        .then((res) => {
          setModels(res.data);
          if (
            res.data
              .find((item) => item.model === dvc.model)
              .types.includes("C")
          ) {
            setClimate(true);
            setLoadClimate(true);
          }
        })
        .catch((err) => {
          console.log(err);
          // t() da problema com o useEffect
          toastr.warning("Erro!", "Erro ao buscar modelos.");
        });
    }
  }, [formManuf, dvc]);

  function onSubmitForm() {
    if (!formManuf || !formModel || !formDesc) {
      setInvalidManuf(!formManuf);
      setInvalidModel(!formModel);
      setInvalidDesc(!formDesc);
      toastr.warning(
        `${t("projTable.att")}!`,
        `${t("projTable.invalidText")}.`
      );
    } else {
      /* Salvar dados do formulário no formato JSON */
      const jsonData = {
        deviceId: dvc.id,
        manufacturer: formManuf,
        model: formModel,
        description: formDesc,
        commInterval: formInterval * intUnit,
        latitude: formLatitude,
        longitude: formLongitude,
        hash: hash,
        accessSecret: networkHash,
        accessKey: deviceAddress,
      };

      setLoading(true);
      /*** Envio de dados ***/
      axios
        .put("editLoraDevice/", JSON.stringify(jsonData))
        .then((res) => {
          setLoading(false);
          toastr.success(res.data.messageHead, res.data.messageBody);
          setLoadDevices(true); // Atualiza tabela
          toggle(); // ...fechar o modal
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
          console.log(err.response);

          if (err.response) {
            /* Notificação de erro de envio */
            toastr.warning(
              err.response.data.messageHead,
              err.response.data.messageBody
            );
          }
        });
    }
  }

  return (
    <Form
      onSubmit={(e) => {
        e.preventDefault();
        onSubmitForm();
      }}
    >
      <ModalBody className="text-left mx-6">
        <h5 className="text-center">Editar LoRaWAN</h5>

        <hr />

        {/*** CAMPO DE FABRICANTE ***/}
        <FormGroup>
          <Label>{t("projTable.manufacturer")}</Label>
          <Input
            type="select"
            name="manufacturer"
            id="manufacturer"
            value={formManuf}
            onChange={(e) => {
              setFormManuf(e.target.value);
              setFormModel("");
              setClimate(false);
              setInvalidManuf(false);
              setInvalidModel(false);
              setInvalidDesc(false);
              setInvalidIR(false);
              setInvalidInterval(false);
            }}
            invalid={invalidManuf}
            disabled={!manufacturers.length || loading}
          >
            <option value="" disabled>
              {t("projTable.select")}
            </option>
            {manufacturers.map((item, i) => (
              <option value={item} key={i}>
                {item}
              </option>
            ))}
            <option value="myDrivers">Meus Drivers</option>
          </Input>
        </FormGroup>

        {/* CAMPO DE MODELO */}
        <FormGroup>
          <Label>{t("projTable.model")}</Label>
          <Input
            type="select"
            name="model"
            id="model"
            value={formModel}
            onChange={(e) => {
              setFormModel(e.target.value);
              setInvalidModel(false);
              setInvalidDesc(false);
              setInvalidIR(false);
              setInvalidInterval(false);

              if (
                models
                  .find((item) => item.model === e.target.value)
                  .types.includes("C")
              ) {
                setClimate(true);
                setFormInterval(60);
                setIntUnit(60);
              } else {
                setClimate(false);
              }
            }}
            // disabled={(!models.length)}
            invalid={invalidModel}
            disabled={!models.length || loading}
          >
            <option value="" disabled>
              {t("projTable.select")}
            </option>
            {models.map((item, i) => (
              <option value={item.model} key={i}>
                {item.model}
              </option>
            ))}
          </Input>
        </FormGroup>

        {/* CAMPO DE DESCRIÇÃO */}
        <FormGroup>
          <Label>{t("projTable.reference")}</Label>
          <Input
            type="text"
            name="description"
            id="description"
            value={formDesc}
            onChange={(e) => {
              setFormDesc(e.target.value);
              setInvalidDesc(false);
            }}
            invalid={invalidDesc}
            disabled={loading}
          />
        </FormGroup>

        {/* HASH */}
        <FormGroup>
          <Label>Hash</Label>
          <Input
            type="text"
            name="hash"
            id="hash"
            value={hash}
            onChange={(e) => setHash(e.target.value)}
          />
        </FormGroup>

        {/* NETWORK DEVICE */}
        <FormGroup>
          <Label>Network Hash</Label>
          <Input
            type="text"
            name="networdHash"
            id="networdHash"
            value={networkHash}
            onChange={(e) => setNetworkHash(e.target.value)}
          />
        </FormGroup>

        {/* DEVICE ADDRESS */}
        <FormGroup>
          <Label>Device Address</Label>
          <Input
            type="text"
            name="deviceAddress"
            id="deviceAddress"
            value={deviceAddress}
            onChange={(e) => setDeviceAddress(e.target.value)}
          />
        </FormGroup>

        {/*** CAMPO DE TEMPO DE ATUALIZAÇÃO ***/}
        <FormGroup>
          <Label>{t("projTable.commInterval")}</Label>
          <Row>
            <Col md={4}>
              <Input
                type="number"
                name="commInterval"
                id="commInterval"
                value={formInterval}
                onChange={(e) => {
                  setFormInterval(e.target.value);
                  setInvalidInterval(false);
                }}
                invalid={invalidInterval}
                disabled={loading}
              />
            </Col>

            <Col md={4}>
              <Input
                className="pt-0"
                type="select"
                name="intUnit"
                id="intUnit"
                value={intUnit}
                onChange={(e) => setIntUnit(e.target.value)}
                disabled={loading}
              >
                <option value={1}>{t("projTable.seconds")}</option>
                <option value={60}>{t("projTable.minutes")}</option>
                <option value={3600}>{t("projTable.hours")}</option>
              </Input>
            </Col>
          </Row>
        </FormGroup>

        <FormGroup>
          <Row>
            {/* LATITUDE */}
            <Col md={6}>
              <Label>
                {t("projTable.latitude")}
                <FontAwesomeIcon
                  id="latitudeHelpIcon"
                  fixedWidth
                  icon={faQuestionCircle}
                />
                <Tooltip
                  isOpen={tooltipLatitudeOpen}
                  target="latitudeHelpIcon"
                  toggle={toggleTooltipLatitude}
                >
                  {t("projTable.tooltipLatitude")}
                </Tooltip>
              </Label>
              <Input
                type="text"
                name="latitude"
                value={formLatitude}
                onChange={(e) => {
                  setFormLatitude(e.target.value);
                }}
                disabled={loading}
              />
            </Col>

            {/* LONGITUDE */}
            <Col md={6}>
              <Label>
                {t("projTable.longitude")}
                <FontAwesomeIcon
                  id="longitudeHelpIcon"
                  fixedWidth
                  icon={faQuestionCircle}
                />
                <Tooltip
                  isOpen={tooltipLongitudeOpen}
                  target="longitudeHelpIcon"
                  toggle={toggleTooltipLongitude}
                >
                  {t("projTable.tooltipLongitude")}
                </Tooltip>
              </Label>
              <Input
                type="text"
                name="longitude"
                value={formLongitude}
                onChange={(e) => {
                  setFormLongitude(e.target.value);
                }}
                disabled={loading}
              />
            </Col>
          </Row>
        </FormGroup>
      </ModalBody>

      <ModalFooter>
        {loading ? (
          <Spinner color="primary" className="mr-3" />
        ) : (
          <>
            {/** BOTÃO DE CANCELAR */}
            <Button
              type="button"
              color="primary"
              className="mr-1"
              onClick={toggle}
              outline
            >
              {t("projTable.cancel")}
            </Button>

            {/** BOTÃO DE ENVIAR DADOS */}
            <Button name="btnClose" type="submit" color="primary">
              {t("projTable.saveChanges")}
            </Button>
          </>
        )}
      </ModalFooter>
    </Form>
  );
};

/** MODAL DE EDIÇÃO DE REGISTRO DE DISPOSITIVO */
const ModalEditDevice = ({ open, toggle, dvc, setLoadDevices, deviceList }) => {
  const { t } = useTranslation();
  console.log(dvc);
  return (
    <Modal isOpen={open} toggle={toggle}>
      <ModalHeader toggle={toggle}>
        {t("projTable.editDevice")} {dvc.description}
      </ModalHeader>

      {dvc.category === "pp" ? (
        <ModalEditPP
          toggle={toggle}
          dvc={dvc}
          setLoadDevices={setLoadDevices}
          deviceList={deviceList}
        />
      ) : dvc.category === "gw" ? (
        <ModalEditGW
          toggle={toggle}
          dvc={dvc}
          setLoadDevices={setLoadDevices}
        />
      ) : dvc.category === "lw" ? (
        <ModalEditLW
          toggle={toggle}
          dvc={dvc}
          setLoadDevices={setLoadDevices}
        />
      ) : (
        <ModalEditSD
          toggle={toggle}
          dvc={dvc}
          setLoadDevices={setLoadDevices}
        />
      )}
    </Modal>
  );
};

const ALARM_TYPES = {
  regular: "regular",
  binary: "binary",
};

const COLOR_ALARM_TYPES = {
  regular: "blue",
  binary: "red",
};

const priorities = [
  {
    value: "low",
    label: "Baixa",
  },
  {
    value: "normal",
    label: "Normal",
  },
  {
    value: "high",
    label: "Alta",
  },
];

const FormAlarm = ({ users, variable, dvc, alarmConfig }) => {
  const { t, i18n } = useTranslation();
  const [activeAlarm, setActiveAlarm] = useState(
    alarmConfig ? alarmConfig.active === 1 : false
  );
  const [whatsApp, setWhatsApp] = useState(
    alarmConfig ? alarmConfig.whatsapp === 1 : false
  );
  const [email, setEmail] = useState(
    alarmConfig ? alarmConfig.email === 1 : false
  );
  const [phone, setPhone] = useState(
    alarmConfig ? alarmConfig.phone === 1 : false
  );
  const [userSelected, setUserSelected] = useState(
    alarmConfig !== null ? alarmConfig.users.map((a) => a.id) : []
  );
  const queryClient = useQueryClient();
  const [priority, setPriority] = useState(
    alarmConfig ? alarmConfig.priority : "normal"
  );

  const [loading, setLoading] = useState(false);

  const handleUpdateAlarm = async () => {
    setLoading(true);
    const data = {
      deviceId: dvc.id,
      variable,
      whatsApp: whatsApp ? 1 : 0,
      email: email ? 1 : 0,
      phone: phone ? 1 : 0,
      users: userSelected,
      active: activeAlarm ? 1 : 0,
      priority,
    };

    try {
      const { data: response } = await axios.post(
        "deviceAlarms/updateAlarm",
        data
      );
      toastr.success(response.messageHead, response.messageBody);
      queryClient.invalidateQueries(["deviceAlarms", dvc.model, dvc.id]);
    } catch (error) {
      console.log(error);
      toastr.error("Erro!", "Erro ao atualizar alarme.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="d-flex flex-column  align-items-end mb-2">
      <div className="d-flex w-100 align-items-center mb-2">
        <AntForm.Item label={`${t("alarms.alarm")}`} className="mb-0  w-100">
          <Switch
            size="small"
            checked={activeAlarm}
            onChange={(checked) => setActiveAlarm(checked)}
          />
        </AntForm.Item>
      </div>
      <div className="d-flex w-100 align-items-center mb-2 pr-3">
        <AntForm.Item label={`${t("alarms.contact")}`} className="mb-0 w-100">
          <AntSelect
            size="small"
            placeholder={`${t("alarms.contact")}`}
            className="ml-2 w-100"
            value={userSelected}
            onChange={(value) => setUserSelected(value)}
            mode="multiple"
          >
            {users?.map((user) => (
              <AntSelect.Option key={user.id} value={user.id}>
                {user.name}
              </AntSelect.Option>
            ))}
          </AntSelect>
        </AntForm.Item>
      </div>
      <div className="d-flex w-100 align-items-center mb-2 pr-3">
        <AntForm.Item label={`${t("alarms.priority")}`} className="mb-0 w-100">
          <AntSelect
            size="small"
            placeholder={`${t("alarms.priority")}`}
            className="ml-2 w-100"
            value={priority}
            onChange={(value) => setPriority(value)}
          >
            {priorities.map((p) => (
              <AntSelect.Option key={p.value} value={p.value}>
                {i18n.language !== "en" ? p.label : p.value}
              </AntSelect.Option>
            ))}
          </AntSelect>
        </AntForm.Item>
      </div>
      <div className="d-flex flex-row w-100 mb-0 gap-4">
        <AntForm.Item label="Whatsapp" className="mb-0 mr-4">
          <Switch
            onChange={(checked) => setWhatsApp(checked)}
            size="small"
            checked={whatsApp}
          />
        </AntForm.Item>
        <AntForm.Item label="Email" className="mb-0 mr-4">
          <Switch
            onChange={(checked) => setEmail(checked)}
            size="small"
            checked={email}
          />
        </AntForm.Item>

        <AntForm.Item label={`${t("alarms.phone")}`} className="mb-0 mr-4">
          <Switch
            onChange={(checked) => setPhone(checked)}
            size="small"
            checked={phone}
          />
        </AntForm.Item>
      </div>

      <Button
        onClick={handleUpdateAlarm}
        loading={loading}
        disabled={loading || !userSelected}
      >
        {t("alarms.saveChanges")}
      </Button>
    </div>
  );
};

const ModalAlarms = ({ open, toggle, dvc }) => {
  const { t } = useTranslation();

  const [
    { data: alarms, status: statusAlarms },
    { data: users, status: statusUsers },
  ] = useQueries({
    queries: [
      {
        queryKey: ["deviceAlarms", dvc.model, dvc.id],
        queryFn: async ({ queryKey }) => {
          const [, model, id] = queryKey;
          const response = await axios.get("deviceAlarms/", {
            params: {
              model,
              id,
            },
          });
          return response.data;
        },
      },
      {
        queryKey: ["v2/user/listUsersByDevice", dvc.id],
        queryFn: async ({ queryKey }) => {
          const [, id] = queryKey;
          const response = await axios.get("v2/user/listUsersByDevice", {
            params: {
              deviceId: id,
            },
          });
          return response.data;
        },
      },
    ],
  });

  const isLoading = statusAlarms === "loading" || statusUsers === "loading";

  return (
    <Modal isOpen={open} toggle={toggle}>
      <ModalHeader toggle={toggle}>
        {t("projTable.defaultAlarmsHead")} {dvc.description}
      </ModalHeader>

      <ModalBody
        className="text-left mx-3"
        style={{
          maxHeight: "80vh",
          overflowY: "auto",
        }}
      >
        {isLoading ? (
          <div className="text-center">
            <Spinner color="primary" />
          </div>
        ) : alarms.length === 0 ? (
          <div className="text-center">
            <h5>{t("alarms.noAlarms")}</h5>
          </div>
        ) : (
          alarms.map((item, i) => (
            <Badge.Ribbon
              text={
                ALARM_TYPES[item.type]
                  ? t(`alarms.${ALARM_TYPES[item.type]}`)
                  : t("alarms.notDefined")
              }
              color={COLOR_ALARM_TYPES[item.type] ?? "gray"}
            >
              <CardAntd
                title={item.description}
                key={i}
                size="small"
                style={{
                  marginBottom: "10px",
                  width: "100%",
                  borderRadius: "10px",
                }}
              >
                <FormAlarm
                  users={users}
                  variable={item.varName}
                  dvc={dvc}
                  alarmConfig={item.alarmConfig}
                />
                <Collapse size="small">
                  <Collapse.Panel
                    header={`${item.alarmMessages.length} ${t(
                      "alarms.messages"
                    )}`}
                    size="small"
                  >
                    <List
                      bordered
                      dataSource={item.alarmMessages.filter(
                        (a) => a.length > 0
                      )}
                      size="small"
                      renderItem={(msg, i) => (
                        <List.Item key={i}>
                          <List.Item.Meta description={msg} />
                        </List.Item>
                      )}
                    />
                  </Collapse.Panel>
                </Collapse>
              </CardAntd>
            </Badge.Ribbon>
          ))
        )}
      </ModalBody>

      <ModalFooter>
        <Button
          name="btnClose"
          type="button"
          color="primary"
          outline
          onClick={toggle}
        >
          {t("projTable.close")}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

/** MODAL DE GERENCIAMENTO DE FATORES DE MULTIPLICAÇÃO */
const ModalDeviceGains = ({ open, toggle, dvc }) => {
  const { t } = useTranslation();

  const [loadVarList, setLoadVarList] = useState(true);
  const [loading, setLoading] = useState(true);
  const [varList, setVarList] = useState([]);
  const [gainList, setGainList] = useState([]);
  const [formTag, setFormTag] = useState("");
  const [formGain, setFormGain] = useState("");
  const [formUnit, setFormUnit] = useState("");
  const [gainToEdit, setGainToEdit] = useState();
  const [energyStructure, setEnergyStructure] = useState(null);
  const [newOrUpdatedGain, setNewOrUpdatedGain] = useState("new");
  const [processInitDate, setProcessInitDate] = useState(null);
  const [processEndDate, setProcessEndDate] = useState(null);
  const [formEdit, setFormEdit] = useState(false);
  const [invalidField, setInvalidField] = useState({
    tag: false,
    gain: false,
    date: false,
  });
  const [formulaOrValue, setFormulaOrValue] = useState("value");

  const handleFormulaOrValue = (value) => {
    setFormGain("");
    setFormulaOrValue(value);
  };

  const getVariables = async () => {
    axios
      .get("/v2/device/readVariableGains", {
        params: {
          deviceId: dvc.id,
        },
      })
      .then((response) => {
        setVarList(response.data.varList);
        setGainList(response.data.gainList);
        setEnergyStructure(response.data.energyStructure);
      })
      .catch((err) => {
        console.log(err);
        // t() da problema com o useEffect
        toastr.warning("Erro!", "Erro ao buscar fatores de multiplicação.");
      })
      .finally(() => {
        setLoadVarList(false);
        setLoading(false);
      });
  };

  useEffect(() => {
    getVariables();
  }, [dvc]);

  function onAddGain() {
    if (
      !formTag ||
      !formGain ||
      (newOrUpdatedGain === "new" &&
        energyStructure?.includes(formTag) &&
        !processInitDate)
    ) {
      setInvalidField({
        tag: !formTag,
        gain: !formGain,
        date: !processInitDate,
      });

      toastr.warning(`${t("projTable.att")}!`, `${t("fieldRequired")}.`);
    } else {
      const jsonData = {
        deviceId: dvc.id,
        dvcModel: dvc.model,
        varName: varList.find((item) => item.tag === formTag).varName,
        varTag: formTag,
        gain: formGain,
        unit: formUnit,
        varTagEnergy: energyStructure?.includes(formTag),
        newOrUpdatedGain,
        processInitDate: moment(
          processInitDate,
          "DD/MM/YYYY HH:mm"
        ).toISOString(),
        formula: formulaOrValue !== "value",
        processEndDate: moment(
          processEndDate,
          "DD/MM/YYYY HH:mm"
        ).toISOString(),
      };

      setLoading(true);
      axios
        .post("v2/device/addGain", JSON.stringify(jsonData))
        .then((res) => {
          setFormTag("");
          setFormGain("");
          let arr = gainList;
          arr.push(res.data.newGain);
          setGainList(arr);
          getVariables();

          toastr.success(
            `${res.data.messageHead}!`,
            `${res.data.messageBody}.`
          );
        })
        .catch((err) => {
          console.log(err);
          toastr.warning(
            `${t("projTable.error")}!`,
            `${t("projTable.addGainError")}.`
          );
        })
        .finally(() => setLoading(false));
    }
  }

  function onEditGain() {
    if (
      !formGain ||
      (newOrUpdatedGain === "new" &&
        energyStructure?.includes(formTag) &&
        !processInitDate)
    ) {
      setInvalidField({
        tag: !formTag,
        gain: !formGain,
        date: !processInitDate,
      });

      toastr.warning(
        `${t("projTable.att")}!`,
        `${t("projTable.editGainWarning")}.`
      );
    } else {
      const jsonData = {
        deviceId: dvc.id,
        gainId: gainToEdit.id,
        dvcModel: dvc.model,
        varName: varList.find((item) => item.tag === formTag).varName,
        varTag: formTag,
        gain: formulaOrValue !== "value" ? String(formGain).trim() : formGain,
        unit: formUnit,
        varTagEnergy: energyStructure?.includes(formTag),
        newOrUpdatedGain,
        processInitDate: moment(
          processInitDate,
          "DD/MM/YYYY HH:mm"
        ).toISOString(),
        formula: formulaOrValue !== "value",
        processEndDate: moment(
          processEndDate,
          "DD/MM/YYYY HH:mm"
        ).toISOString(),
      };

      setLoading(true);
      axios
        .put("v2/device/editGain", JSON.stringify(jsonData))
        .then((res) => {
          setFormTag("");
          setFormGain("");
          setFormUnit("");
          const arr = gainList.map((item) =>
            item.id === gainToEdit.id ? res.data.editedGain : item
          );
          setGainList(arr);
          setGainToEdit();

          getVariables();
          toastr.success(
            `${res.data.messageHead}!`,
            `${res.data.messageBody}.`
          );
        })
        .catch((err) => {
          console.log(err);
          toastr.warning(
            `${t("projTable.error")}!`,
            `${t("projTable.editGainError")}.`
          );
        })
        .finally(() => setLoading(false));
    }
  }

  function onRemoveGain(gainId, gainType) {
    setLoading(true);
    axios
      .delete("v2/device/removeGain", { data: { gainId: gainId, gainType } })
      .then((res) => {
        setGainList(gainList.filter((x) => x.id !== gainId));
        toastr.success(`${res.data.messageHead}!`, `${res.data.messageBody}.`);
      })
      .catch((err) => {
        console.log(err);
        toastr.warning(
          `${t("projTable.error")}!`,
          `${t("projTable.removeGainError")}.`
        );
      })
      .finally(() => setLoading(false));
  }

  const energyCols = [
    {
      dataField: "startDate",
      text: "StartDate",
      headerStyle: { width: "21%" },
      // sort: true,
      formatter: (cell) =>
        cell ? moment.unix(cell).format("DD/MM/YYYY HH:mm") : "-",
    },
    {
      dataField: "endDate",
      text: "EndDate",
      headerStyle: { width: "21%" },
      // sort: true,
      formatter: (cell) =>
        cell ? moment.unix(cell).format("DD/MM/YYYY HH:mm") : "-",
    },
  ];

  const mainCols = [
    {
      // ID (oculto)
      dataField: "id",
      text: t("projTable.id"),
      sort: true,
      hidden: true,
      isKey: true,
    },
    {
      // nome da variável
      dataField: "varName",
      text: t("projTable.variable"),
      sort: true,
    },
    {
      // fator de multiplicação
      dataField: "gain",
      text: t("projTable.value"),
      headerStyle: { width: "20%" },
      sort: true,
      formatter: (cell) => {
        if (typeof cell === "string") {
          return cell;
        } else {
          return String(+cell).replace(".", ",");
        }
      },
    },
    {
      // unidade
      dataField: "unit",
      text: "Unidade",
      headerStyle: { width: "10%" },
      // sort: true,
      formatter: (cell) => (cell ? cell : "-"),
    },
  ];

  const actionCols = [
    {
      // botões de ação
      dataField: "actions",
      text: t("projTable.actions"),
      headerStyle: { width: "11%" },
      formatter: (_, row) => (
        <div className="d-flex justify-content-left">
          <div // editar
            id={"editGain" + row?.id}
            className="align-middle cursor-pointer"
            onClick={() => {
              const object = gainList.find((item) => item.id === row.id);
              const hasFormula = object.hasFormula;

              hasFormula
                ? setFormulaOrValue("formula")
                : setFormulaOrValue("value");

              setNewOrUpdatedGain("update");
              setFormEdit(true);
              setGainToEdit(object);
              setFormTag(object.varTag);
              setFormGain(object.gain);
              setFormUnit(object.unit);
              setInvalidField({ ...invalidField, tag: false, gain: false });
              const startDateUnix = gainList.find(
                (item) => item.id === row.id
              ).startDate;
              if (startDateUnix) {
                const formatDate = moment.unix(startDateUnix);
                setProcessInitDate(formatDate);
              }
              const endDateUnix = gainList.find(
                (item) => item.id === row.id
              )?.endDate || null;
              if (endDateUnix) {
                const formatDate = moment.unix(endDateUnix);
                setProcessEndDate(formatDate);
              }
            }}
          >
            <FontAwesomeIcon icon={faPen} fixedWidth />
            {/* Tooltip */}
            <UncontrolledTooltip placement="top" target={"editGain" + row?.id}>
              {t("projTable.edit")}
            </UncontrolledTooltip>
          </div>

          <div // remover
            id={"removeGain" + row?.id}
            className="align-middle cursor-pointer"
            onClick={() =>
              onRemoveGain(
                row.id,
                energyStructure?.includes(row.varTag) ? "energy" : "other"
              )
            }
          >
            <FontAwesomeIcon icon={faTrash} fixedWidth />
            {/* Tooltip */}
            <UncontrolledTooltip
              placement="top"
              target={"removeGain" + row?.id}
            >
              {t("projTable.delete")}
            </UncontrolledTooltip>
          </div>
        </div>
      ),
    },
  ];

  const gainCols = energyStructure
    ? [...mainCols, ...energyCols, ...actionCols]
    : [...mainCols, ...actionCols];

  /* Ícones de ordenação */
  const sortIcon = {
    sortCaret: (order) =>
      !order ? (
        <FontAwesomeIcon className={"fa-xs ml-1"} icon={faSort} />
      ) : order === "asc" ? (
        <FontAwesomeIcon className={"fa-xs ml-1"} icon={faSortUp} />
      ) : (
        <FontAwesomeIcon className={"fa-xs ml-1"} icon={faSortDown} />
      ),
  };

  return (
    <Modal isOpen={open} toggle={toggle}>
      <ModalHeader toggle={toggle}>
        {t("projTable.manageGainsHead")} {dvc.description}
      </ModalHeader>

      <ModalBody className="text-left mx-3">
        <Row>
          <Col>
            <Row>
              {/*** CAMPO DE VARIÁVEL ***/}
              <Col>
                <FormGroup className="mb-0">
                  <Label className="ml-1">{t("projTable.variable")}</Label>
                  <Input
                    type="select"
                    name="varTag"
                    id="varTag"
                    value={formTag}
                    onChange={(e) => {
                      setNewOrUpdatedGain("new");
                      setFormEdit(false);
                      setFormGain("");
                      setProcessInitDate(null);
                      setFormTag(e.target.value);
                      setGainToEdit(false);
                      setInvalidField({ ...invalidField, tag: false });

                      // let unit = varList.find(item => item.tag === e.target.value).unit;
                      setFormUnit(
                        varList.find((item) => item.tag === e.target.value).unit
                      );
                    }}
                    disabled={loading}
                    invalid={invalidField.tag}
                  >
                    <option value="" disabled>
                      {t("projTable.select")}
                    </option>
                    {varList.map((element, index) =>
                      !gainList.find((item) => item?.varTag === element.tag) ||
                      (gainToEdit && element.tag === gainToEdit?.varTag) ? (
                        <option value={element.tag} key={index}>
                          {element.varName}
                        </option>
                      ) : null
                    )}
                  </Input>
                </FormGroup>
              </Col>

              {/*** CAMPO DE GANHO ***/}
              <Col>
                {" "}
                <FormGroup className="mb-0">
                  <Label className="ml-1">
                    {t("projTable.value")} -{" "}
                    {formulaOrValue === "value" ? (
                      "numérico"
                    ) : (
                      <>
                        formula
                        <BiInfoCircle
                          style={{ marginLeft: "4px" }}
                          title="Utilize o caractere 'x' para representar o valor da variável"
                        />
                      </>
                    )}
                  </Label>
                  <div style={{ display: "flex" }}>
                    <Input
                      type={formulaOrValue === "value" ? "number" : "text"}
                      name="varGain"
                      id="varGain"
                      value={formGain}
                      onChange={(e) => {
                        setFormGain(e.target.value);
                        setInvalidField({ ...invalidField, gain: false });
                      }}
                      disabled={loading}
                      invalid={invalidField.gain}
                      style={{ borderRadius: "3px 0 0 3px" }}
                      placeholder={
                        formulaOrValue === "value" ? "" : "ex: x-100*0,32"
                      }
                    />
                    <Button
                      color="primary"
                      size="small"
                      style={{
                        borderRadius: "0 3px 3px 0",
                        width: "32px",
                        height: "24px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        padding: 0,
                      }}
                      onClick={() => {
                        handleFormulaOrValue(
                          formulaOrValue === "value" ? "formula" : "value"
                        );
                      }}
                    >
                      <FiRefreshCcw />
                    </Button>
                  </div>
                </FormGroup>
              </Col>

              {/*** CAMPO DE UNIDADE ***/}
              <Col>
                {" "}
                <FormGroup className="mb-0">
                  <Label className="ml-1">Unidade</Label>
                  <Input
                    type="text"
                    name="varUnit"
                    id="varUnit"
                    value={formUnit === null ? "" : formUnit}
                    onChange={(e) => setFormUnit(e.target.value)}
                    disabled={loading}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row
              md="12"
              className="d-flex justify-content-between m-0 mt-1 p-0"
            >
              {energyStructure?.includes(formTag) && (
                <FormGroup>
                  <Label
                    className="ml-1"
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    {t("projTable.processInitDate")}
                  </Label>
                  <DatePicker
                    defaultValue={processInitDate}
                    showTime
                    onChange={(date, dateString) => {
                      setProcessInitDate(dateString);
                      setInvalidField({ ...invalidField, date: false });
                    }}
                    status={invalidField.date ? "error" : ""}
                    format={"DD/MM/YYYY HH:mm"}
                    size="small"
                  />
                </FormGroup>
              )}

              {(energyStructure?.includes(formTag)) && (
                <FormGroup>
                  <Label
                    className="ml-1"
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    {t("projTable.processEndDate")}
                  </Label>
                  <DatePicker
                    defaultValue={processEndDate}
                    showTime
                    onChange={(date, dateString) => {
                      setProcessEndDate(dateString);
                      setInvalidField({ ...invalidField, date: false });
                    }}
                    status={invalidField.date ? "error" : ""}
                    format={"DD/MM/YYYY HH:mm"}
                    size="small"
                  />
                </FormGroup>
              )}
              {formEdit && energyStructure?.includes(formTag) && (
                <>
                  <Col className="d-flex align-items-center">
                    <FormGroup check className="mr-3">
                      <Label check>
                        <Input
                          type="radio"
                          name="actionType"
                          value="update"
                          checked={newOrUpdatedGain === "update"}
                          onChange={() => {
                            setNewOrUpdatedGain("update");
                          }}
                        />{" "}
                        Alterar fator atual
                      </Label>
                    </FormGroup>{" "}
                    <FormGroup check>
                      <Label check>
                        <Input
                          type="radio"
                          name="actionType"
                          value="new"
                          checked={newOrUpdatedGain === "new"}
                          onChange={() => {
                            setNewOrUpdatedGain("new");
                          }}
                        />{" "}
                        Fator novo
                      </Label>
                    </FormGroup>
                  </Col>
                </>
              )}
            </Row>
          </Col>
          {/* BOTÃO [+] */}
          <Col
            sm="1"
            xs="2"
            className="d-flex align-items-center justify-content-center"
          >
            <Button
              size="sm"
              color="primary"
              onClick={() => (gainToEdit ? onEditGain() : onAddGain())}
              disabled={loading}
            >
              {loading ? (
                <Spinner size="sm" />
              ) : gainToEdit ? (
                <FontAwesomeIcon fixedWidth icon={faCheck} />
              ) : (
                <FontAwesomeIcon fixedWidth icon={faPlus} />
              )}
            </Button>
          </Col>
        </Row>
        <hr />

        {loadVarList ? (
          <div className="text-center">
            <Spinner color="primary" />
          </div>
        ) : !gainList.length ? (
          <p>{t("projTable.noGains")}</p>
        ) : (
          <BootstrapTable
            bootstrap4
            keyField="id"
            data={gainList}
            columns={gainCols}
            condensed
            bordered={false}
            striped={true}
            sort={sortIcon}
          />
        )}
      </ModalBody>
      <ModalFooter>
        {/*** BOTÃO DE FECHAR O MODAL ***/}
        <Button
          name="btnClose"
          type="button"
          color="primary"
          outline
          onClick={toggle}
        >
          {t("projTable.close")}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

const CreateFormulaTab = ({
  form,
  formula,
  setFormula,
  data,
  onSubmit,
  isEditing,
  cancelEdit
}) => {
  const handleVariableClick = (tag) => {
    setFormula((prev) => `${prev} ${tag}`);
  };

  return (
    <AntForm
      layout="vertical"
      onFinish={onSubmit}
      form={form}
      initialValues={{ formulaName: "", description: "" }}
      style={{
        maxHeight: "75vh",
        overflowY: "auto",
      }}
    >
      {/* Nome da Fórmula */}
      <AntForm.Item
        label="Nome da Fórmula"
        name="formulaName"
        rules={[
          {
            required: true,
            message: "Por favor, insira o nome da fórmula.",
          },
        ]}
      >
        <AntInput placeholder="Digite o nome da fórmula" disabled={isEditing} />
      </AntForm.Item>

      {/* Descrição da Fórmula */}
      <AntForm.Item
        label="Descrição da Fórmula"
        name="description"
        rules={[
          {
            required: true,
            message: "Por favor, insira a descrição da fórmula.",
          },
        ]}
      >
        <AntInput.TextArea
          placeholder="Digite a descrição da fórmula"
          disabled={isEditing}
        />
      </AntForm.Item>

      {/* Variáveis Disponíveis */}
      <AntForm.Item label="Variáveis Disponíveis">
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            gap: "8px",
            maxHeight: "20vh",
            overflowY: "auto",
          }}
        >
          {data.driver.map((variable) => (
            <AntButton
              key={variable.tag}
              onClick={() => handleVariableClick(variable.tag)}
              title={variable.varDesc}
            >
              {variable.varName} {variable.unit ? `(${variable.unit})` : ""}
            </AntButton>
          ))}
        </div>
      </AntForm.Item>

      <AntForm.Item label="Fórmula Criada">
        <AntInput.TextArea
          value={formula}
          onChange={(e) => setFormula(e.target.value)}
          placeholder="Clique nas variáveis acima ou edite diretamente"
          rows={4}
        />
      </AntForm.Item>
      <Collapse className="mb-2">
        <Collapse.Panel header="Exemplos de Fórmulas" key="1">
          <p>
            - Soma simples: <code>VAR1 + VAR2</code>
          </p>
          <p>
            - Multiplicação: <code>VAR1 * VAR2</code>
          </p>
          <p>
            - Condicional: <code>VAR1 {">"} 100 ? VAR1 * 2 : VAR1 / 2</code>
          </p>
          <p>
            - Uso de parênteses: <code>(VAR1 + VAR2) * VAR3</code>
          </p>
          <p>
            - Função customizada (opcional):{" "}
            <code>IF(VAR1 {">"} 100, VAR2, VAR3)</code>
          </p>
          <small>
            <strong>Nota:</strong> Substitua <code>VAR1</code>,{" "}
            <code>VAR2</code>, etc. pelas variáveis disponíveis.
          </small>
        </Collapse.Panel>
      </Collapse>
      <AntForm.Item>
        {isEditing && (
          <AntButton type="ghost" className="mr-2" onClick={cancelEdit}>
            Cancelar Edição
          </AntButton>
        )}
        <AntButton type="primary" htmlType="submit">
          {isEditing ? "Editar Fórmula" : "Criar Fórmula"}
        </AntButton>
      </AntForm.Item>
    </AntForm>
  );
};

const TestFormulaTab = ({
  formula,
  data,
  testValues,
  setTestValues,
  onTest,
  testResult,
}) => {
  // Filtra as variáveis usadas na fórmula
  const usedVariables = data.driver.filter((variable) =>
    new RegExp(`\\b${variable.tag}\\b`).test(formula)
  );

  const handleInputChange = (e, tag) => {
    const value = e.target.value;

    // Garante que o valor inserido seja numérico ou vazio
    if (/^\d*\.?\d*$/.test(value)) {
      setTestValues((prev) => ({
        ...prev,
        [tag]: value,
      }));
    }
  };

  return (
    <div>
      <h5>Teste de Fórmula</h5>
      <p>Insira valores para as variáveis:</p>
      {usedVariables.map((variable) => (
        <AntForm.Item
          key={variable.tag}
          label={`${variable.varName} ${
            variable.unit ? `(${variable.unit})` : ""
          }`}
        >
          <AntInput
            type="text"
            value={testValues[variable.tag] || ""}
            onChange={(e) => handleInputChange(e, variable.tag)}
            placeholder={`Valor para ${variable.varName}`}
          />
        </AntForm.Item>
      ))}
      <AntButton type="primary" onClick={onTest}>
        Testar Fórmula
      </AntButton>
      {testResult !== null && (
        <div style={{ marginTop: "16px" }}>
          <h6>Resultado:</h6>
          <p>
            <strong>{testResult}</strong>
          </p>
        </div>
      )}
    </div>
  );
};

const ListFormulaTab = React.forwardRef(({ dvc, handleEditFormula }, ref) => {
  const { data, isLoading, refetch } = useQuery({
    queryKey: ["getDevicesFormulas", dvc.id],
    queryFn: async ({ queryKey }) => {
      const [, id] = queryKey;
      const response = await axios.get("getDevicesFormulas/", {
        params: { id },
      });
      return response.data;
    },
  });

  useImperativeHandle(
    ref,
    () => {
      return {
        refetch,
      };
    },
    [refetch]
  );

  const [loadingDelete, setLoadingDelete] = useState(false);

  const handleRemoveFormula = async (record) => {
    setLoadingDelete(true);
    try {
      const { data } = await axios.delete("removeDeviceFormula/", {
        params: { id: record.id },
      });
      toastr.success(data.message);
      refetch();
    } catch (error) {
      toastr.error("Erro!", "Erro ao remover fórmula.");
    } finally {
      setLoadingDelete(false);
    }
  };

  if (!data && isLoading) {
    return (
      <div className="text-center">
        <Spinner color="primary" />
      </div>
    );
  }

  return (
    <TableAntd
      size="small"
      columns={[
        {
          title: "Nome da Fórmula",
          dataIndex: "name",
          key: "name",
        },
        {
          title: "Descrição",
          dataIndex: "description",
          key: "description",
        },
        {
          title: "Fórmula",
          dataIndex: "formula",
          key: "formula",
        },
        {
          title: "Ações",
          key: "actions",
          render: (text, record) => (
            <Space size="middle">
              <AntButton
                type="primary"
                onClick={() => handleEditFormula(record)}
              >
                Editar
              </AntButton>
              <Popconfirm
                title="Tem certeza que deseja remover esta fórmula?"
                onConfirm={() => handleRemoveFormula(record)}
                okText="Sim"
                cancelText="Não"
                disabled={loadingDelete}
              >
                <AntButton type="danger" disabled={loadingDelete}>
                  {loadingDelete ? <Spinner size="sm" /> : "Remover"}
                </AntButton>
              </Popconfirm>
            </Space>
          ),
        },
      ]}
      dataSource={data}
      scroll={{ y: 400 }}

    />
  );
});

const ModalVariables = ({ dvc, open, toggle }) => {
  const [formula, setFormula] = useState("");
  const [activeTab, setActiveTab] = useState("create");
  const [testValues, setTestValues] = useState({});
  const [testResult, setTestResult] = useState(null);
  const [form] = AntForm.useForm();
  const listaRef = useRef();
  const [formulaId, setFormulaId] = useState(null);

  const { data, isLoading } = useQuery({
    queryKey: ["getDriver", dvc.id],
    queryFn: async ({ queryKey }) => {
      const [, id] = queryKey;
      const response = await axios.get("getDriver/", {
        params: { id },
      });
      return response.data;
    },
  });

  const validateFormula = (formula) => {
    const validVariables = data.driver.map((v) => v.tag);

    // Substituir as variáveis pela constante "1" para validação
    const sanitizedFormula = formula
      // Substitui as variáveis pelas constantes "1" para a validação
      .replace(new RegExp(`\\b(${validVariables.join("|")})\\b`, "g"), "1")
      // Substitui a função IF por uma expressão ternária
      .replace(
        /\bIF\s*\(\s*([^\)]+?)\s*,\s*([^\)]+?)\s*,\s*([^\)]+?)\s*\)/g,
        "($1) ? $2 : $3"
      );

    // Verificar se a fórmula contém apenas caracteres válidos
    if (!/^[0-9+\-*/()?.:<>=\s]*$/.test(sanitizedFormula)) {
      throw new Error(
        "A fórmula contém caracteres inválidos. Use apenas números, variáveis, operadores (+, -, *, /, ?, :, <, >, =) e parênteses."
      );
    }

    // Tentar avaliar a fórmula com a função Function
    try {
      new Function(`return (${sanitizedFormula})`)();
    } catch (error) {
      throw new Error("A fórmula não é válida.");
    }
  };

  const handleSubmit = async (values) => {
    try {
      if (values.formulaName.includes(" ")) {
        toastr.error("Erro!", "O nome da fórmula não pode conter espaços.");
        return;
      }
      validateFormula(formula);
      toastr.success("Fórmula válida! Submetendo...");
      

      //verifica o nome da formula tem espacos
      
      if(formulaId) {

        await axios.put("editDeviceFormula", {
          id: formulaId,
          formula,
          name: values.formulaName,
          description: values.description,
          tag: `${values.formulaName.replace(/\s/g, "_").toLowerCase()}`,
        });
        toastr.success("Fórmula editada com sucesso!");
        setFormula("");
        setTestValues({});
        setTestResult(null);
        form.resetFields();
        setActiveTab("list");
        listaRef.current.refetch();

      }else{

        const { data } = await axios.post("addDeviceFormula", {
          deviceId: dvc.id,
          formula,
          name: values.formulaName,
          description: values.description,
          tag: `${values.formulaName}`,
        });
        toastr.success("Fórmula criada com sucesso!");
        setFormula("");
        setTestValues({});
        setTestResult(null);
        form.resetFields();
        setActiveTab("list");
        listaRef.current.refetch();
      }

    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response.data.message) {
          toastr.error(error.response.data.message);
        } else {
          toastr.error("Erro ao criar fórmula.");
        }
      } else {
        toastr.error(error.message);
      }
    }
  };

  const handleTestFormula = () => {
    try {
      // Substitui as variáveis pelos valores inseridos
      let formulaToTest = formula.replace(
        new RegExp(`\\b(${data.driver.map((v) => v.tag).join("|")})\\b`, "g"),
        (match) => testValues[match] ?? 0 // Substitui as variáveis com valores ou 0
      );

      // Substitui a função IF por uma expressão ternária, removendo espaços extras
      formulaToTest = formulaToTest.replace(
        /\bIF\s*\(\s*([^\)]+?)\s*,\s*([^\)]+?)\s*,\s*([^\)]+?)\s*\)/g,
        "($1) ? $2 : $3"
      );

      // Avalia a fórmula
      const result = new Function(`return (${formulaToTest})`)();
      setTestResult(result); // Define o resultado da fórmula
      toastr.success("Fórmula testada com sucesso!"); // Exibe uma mensagem de sucesso
    } catch (error) {
      toastr.error("Erro ao testar a fórmula. Verifique os valores inseridos.");
      setTestResult(null); // Limpa o resultado em caso de erro
    }
  };


  const handleEditFormula = (record) => {
    setFormulaId(record.id);
    setFormula(record.formula);
    form.setFieldsValue({
      formulaName: record.name,
      description: record.description,
    });
    setActiveTab("create");
  };

  return (
    <Modal isOpen={true}>
      <ModalHeader >
        Variáveis Virtuais: {dvc.description}
      </ModalHeader>
      <ModalBody>
        {isLoading ? (
          <div className="text-center">
            <Spinner color="primary" />
          </div>
        ) : (
          <AntTabs
            activeKey={activeTab}
            onChange={setActiveTab}
            items={[
              {
                key: "list",
                label: "Lista de Fórmulas",
                children: (
                  <ListFormulaTab
                    data={data}
                    dvc={dvc}
                    ref={listaRef}
                    handleEditFormula={handleEditFormula}
                  />
                ),
              },
              {
                key: "create",
                label: formulaId ? "Editar Fórmula" : "Criar Fórmula",
                children: (
                  <CreateFormulaTab
                    formula={formula}
                    setFormula={setFormula}
                    data={data}
                    onSubmit={handleSubmit}
                    form={form}
                    isEditing={formulaId}
                    cancelEdit={() => {
                      setFormulaId(null);
                      setFormula("");
                      form.resetFields();
                    }}
                  />
                ),
              },
              {
                key: "test",
                label: "Testar Fórmula",
                disabled: !formula.trim(),
                children: (
                  <TestFormulaTab
                    formula={formula}
                    data={data}
                    testValues={testValues}
                    setTestValues={setTestValues}
                    onTest={handleTestFormula}
                    testResult={testResult}
                  />
                ),
              },
            ]}
          />
        )}
      </ModalBody>
      <ModalFooter>
        <Button color="primary" outline onClick={()=> toggle()}>
          Fechar
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export {
  ModalAlarms,
  ModalDeviceGains,
  ModalEditDevice,
  ModalEditProject,
  ModalVariables
};

