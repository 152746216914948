import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import React, {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
const darker = (color, percent) => {
  color = color.replace("#", "");

  const r = parseInt(color.substring(0, 2), 16);
  const g = parseInt(color.substring(2, 4), 16);
  const b = parseInt(color.substring(4, 6), 16);

  const darkenedR = Math.round(r * (1 - percent / 100));
  const darkenedG = Math.round(g * (1 - percent / 100));
  const darkenedB = Math.round(b * (1 - percent / 100));

  const darkenedHex =
    "#" +
    darkenedR.toString(16).padStart(2, "0") +
    darkenedG.toString(16).padStart(2, "0") +
    darkenedB.toString(16).padStart(2, "0");

  return darkenedHex;
};

const lighter = (color, percent) => {
  color = color.replace("#", "");

  const r = parseInt(color.substring(0, 2), 16);
  const g = parseInt(color.substring(2, 4), 16);
  const b = parseInt(color.substring(4, 6), 16);

  const lightenedR = Math.round(r + (255 - r) * (percent / 100));
  const lightenedG = Math.round(g + (255 - g) * (percent / 100));
  const lightenedB = Math.round(b + (255 - b) * (percent / 100));

  const lightenedHex =
    "#" +
    lightenedR.toString(16).padStart(2, "0") +
    lightenedG.toString(16).padStart(2, "0") +
    lightenedB.toString(16).padStart(2, "0");

  return lightenedHex;
};
const ThemeContext = createContext();

const DEFAULT_COLORS = {
  primary: "#007bff",
  secondary: "#8579ca",
  tertiary: "#6c757d",
  success: "#28a745",
  info: "#17a2b8",
  warning: "#ffc107",
  danger: "#dc3545",
};

export const DEFAULT_LIGHT_THEME = {
  "--sidebar-background": "#ffffff",
  "--sidebar-text-color": "#2c2d48",
  "--sidebar-header-text-color": "#23243d",
  "--main-background": "#f1f3f4",
  "--header-background": "#00a81c",
  "--header-text-color": "#000000",
  "--text-color": "#2c2d48",
};

export const DEFAULT_DARK_THEME = {
  "--disabled": "#6c757d",
  "--sidebar-background": "#1a1d23",
  "--sidebar-text-color": "#b9c0cc",
  "--sidebar-header-text-color": "#dfe2e6",
  "--main-background": "#0d0f11",
  "--header-background": "#00a81c",
  "--header-text-color": "#ffffff",
  "--text-color": "#ffffff",
};

const returnDefaultColors = () => {
  return {
    primary: '#3B7DDD',
    secondary: '#6f42c1',
    headerTextColor: 'var(--text-color)',
    backgroundImage: null,
    defaultColorHeaderText: true,
  };
}

const requestUserPreference = async () => {
  try {
    const { data } = await axios.get("/userThemeColors");
    const colors = JSON.parse(data.themeColors);
    if(!colors){
      return returnDefaultColors();
    }
    return {
      primary: colors["--primary-color"],
      secondary: colors["--secondary-color"],
      headerTextColor: colors["defaultColorHeaderText"]
        ? "var(--text-color)"
        : colors["--header-text-color"],
      backgroundImage: colors["--bg-image"],
      defaultColorHeaderText: colors.defaultColorHeaderText,
    };
  } catch (error) {
    console.error("Erro ao carregar preferências do usuário:", error);
    return returnDefaultColors();
  }
};

const DEFAULT_CSS = {
  "--primary-color": DEFAULT_COLORS.primary,
  "--primary-dark-color": darker(DEFAULT_COLORS.primary, 10),
  "--primary-light-color": lighter(DEFAULT_COLORS.primary, 10),
  "--secondary-color": DEFAULT_COLORS.secondary,
  "--success-color": DEFAULT_COLORS.success,
  "--info-color": DEFAULT_COLORS.info,
  "--warning-color": DEFAULT_COLORS.warning,
  "--danger-color": DEFAULT_COLORS.danger,
  "--disabled": "#6c757d",
};

function useLocalStorageState(key, initialValue) {
  const [state, setState] = useState(() => {
    const storedValue = localStorage.getItem(key);
    return storedValue ? JSON.parse(storedValue) : initialValue;
  });

  useEffect(() => {
    const handleStorageChange = () => {
      const updatedValue = localStorage.getItem(key);
      setState(updatedValue ? JSON.parse(updatedValue) : initialValue);
    };

    window.addEventListener("storage", handleStorageChange);
    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, [key, initialValue]);

  const setValue = (value) => {
    localStorage.setItem(key, JSON.stringify(value));
    setState(value);
  };

  return [state, setValue];
}

const ThemeProvider = ({ children }) => {
  const [theme, setTheme] = useState(() => {
    const localTheme = localStorage.getItem("defaultTheme");
    return localTheme && localTheme === "dark" ? "dark" : "light";
  });
  const [loadUserPreference, setLoadUserPreference] = useLocalStorageState(
    "loadUserPreference",
    false
  );

  const [userColors, setUserColors] = useState(() => {
    const userColors = localStorage.getItem("userColors");
    return {
      ...DEFAULT_CSS,
      ...(userColors ? JSON.parse(userColors) : {}),
    };
  });

  const updateUserColors = (newColors) => {
    const updatedColors = {
      ...DEFAULT_CSS,
      "--primary-color": newColors["--primary-color"],
      "--primary-dark-color": darker(newColors["--primary-color"], 10),
      "--primary-light-color": lighter(newColors["--primary-color"], 10),
      "--secondary-color": newColors["--secondary-color"],
      "--header-text-color": newColors["defaultColorHeaderText"]
        ? "var(--text-color)"
        : newColors["--header-text-color"],
      "--bg-image": newColors["--bg-image"]
        ? `url('${newColors["--bg-image"]}')`
        : null,
      defaultColorHeaderText: newColors.defaultColorHeaderText,
    };
    setUserColors(updatedColors);
    localStorage.setItem("userColors", JSON.stringify(updatedColors));
    insertCSSVariables();
  };
  const [userId] = useLocalStorageState("userId", null);

  const { refetch } = useQuery({
    queryKey: ["userPreference", userId],
    queryFn: requestUserPreference,
    enabled: !loadUserPreference,
    onSuccess: (data) => {
      const newColors = {
        ...userColors,
        "--primary": data.primary,
        "--secondary": data.secondary,
        "--primary-color": data.primary,
        "--primary-dark-color": darker(data.primary, 10),
        "--primary-light-color": lighter(data.primary, 10),
        "--secondary-color": data.secondary,
        "--header-text-color": data.headerTextColor,
        "--bg-image": data.backgroundImage
          ? `url('${data.backgroundImage}')`
          : null,
        defaultColorHeaderText: data.defaultColorHeaderText,
      };
      setUserColors(newColors);
      setLoadUserPreference(true);
      localStorage.setItem("userColors", JSON.stringify(newColors));
    },
    refetchInterval: Infinity,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchIntervalInBackground: false,
  });

  useEffect(() => {
    if (!loadUserPreference) {
      refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadUserPreference]);

  const insertCSSVariables = useCallback(() => {
    let style;
    const ExistingStyle = document.getElementById("theme-variables");
    if (ExistingStyle) {
      style = ExistingStyle;
    } else {
      style = document.createElement("style");
      style.type = "text/css";
      style.id = "theme-variables";
    }

    const shadowPrimary =
      "0 0 0 2px " + lighter(userColors["--primary-color"], 10) + "33";
    const shadowSecondary =
      "0 0 0 2px " + lighter(userColors["--secondary-color"], 10) + "33";

    const lightTheme = DEFAULT_LIGHT_THEME;
    const darkTheme = DEFAULT_DARK_THEME;

    const bootstrapVariableMapping = {
      primary: "--primary-color",
      "primary-dark": "--primary-dark-color",
      "primary-light": "--primary-light-color",
      secondary: "--secondary-color",
      success: "--success-color",
      info: "--info-color",
      warning: "--warning-color",
      danger: "--danger-color",
      light: "--light-color",
      dark: "--dark-color",
      "body-bg": "--background-color",
      "body-color": "--text-color",
    };
    const generateBootstrapStyles = (currentTheme) => {
      let bootstrapStyles = "";
      for (const [bootstrapVar, customVar] of Object.entries(
        bootstrapVariableMapping
      )) {
        bootstrapStyles += `
          .bg-${bootstrapVar} { background-color: var(${customVar}) !important ; }
          .border-${bootstrapVar} { border-color: var(${customVar})  ; }
          .text-${bootstrapVar} { color: var(${customVar})  ; }
          .btn-${bootstrapVar} { 
            background-color: var(${customVar})  ;
            border-color: var(${customVar})  ;
          }
          .btn-outline-${bootstrapVar} {
            color: var(${customVar})  ;
            border-color: var(${customVar})  ;
          }
          .link-${bootstrapVar} { color: var(${customVar})  ; }
        `;
      }
      return bootstrapStyles += `
      
        .btn-secondary:focus, .btn-secondary.focus {
          color: #fff;
          background-color: var(--secondary-color);
          border-color: var(--secondary-color);
          box-shadow; ${shadowSecondary};
        }
        .btn-secondary:hover,
        .btn-secondary:not(:disabled):not(.disabled):active, 
        .btn-secondary:not(:disabled):not(.disabled).active, 
        .show > .btn-secondary.dropdown-toggle,
        .btn-outline-secondary:hover,
        .btn-outline-secondary:not(:disabled):not(.disabled):active, 
        .btn-outline-secondary:not(:disabled):not(.disabled).active, 
        .show > .btn-outline-secondary.dropdown-toggle
        {
          color: #fff;
          background-color: var(--secondary-color);
          border-color: var(--secondary-color);
        }
        .btn-secondary:not(:disabled):not(.disabled):active:focus, 
        .btn-secondary:not(:disabled):not(.disabled).active:focus,
        .show > .btn-secondary.dropdown-toggle:focus {
          box-shadow: ${shadowSecondary};
        }
        .nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link {
          background-color: var(--main-background);
          border-color: hsl(from var(--main-background) h s calc(l - 17) );
          color: var(--text-color);
        }
      `;
    };
    const antdVariableMapping = {
      "primary-color": "--primary-color",
      "secondary-color": "--secondary-color",
      "success-color": "--success-color",
      "info-color": "--info-color",
      "warning-color": "--warning-color",
      "danger-color": "--danger-color",
      "text-color": "--text-color",
      "background-color": "--background-color",
      "border-color-base": "--border-color",
      "component-background": "--component-background",
    };

    const generateAntdStyles = (currentTheme) => {
      let antdStyles = "";
      for (const [antdVar, customVar] of Object.entries(antdVariableMapping)) {
        antdStyles += `
          .ant-${antdVar.replace(/_/g, "-")} {
            --antd-${antdVar.replace(/_/g, "-")}: var(${customVar});
          }
        `;

        // Exemplo de como usar a variável em um componente específico:
        antdStyles += `
         .ant-btn-primary {
          background-color: var(--primary-color);
          border-color: var(--primary-color);
        }
           
        .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn, 
        .ant-tabs-tab-btn:focus,.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover, 
        .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover, 
        .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active, 
        .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active, 
        .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open, 
        .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open, 
        .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected, 
        .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected,
        .ant-menu-light .ant-menu-item:hover, 
        .ant-menu-light .ant-menu-item:hover .anticon, 
        .ant-menu-light .ant-menu-item-active, 
        .ant-menu-light .ant-menu-item-active  .anticon, 
        .ant-menu-light .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open, 
        .ant-menu-light .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open  .anticon, 
        .ant-menu-light .ant-menu-submenu-active, 
        .ant-menu-light .ant-menu-submenu-active  .anticon, 
        .ant-menu-light .ant-menu-submenu-title:hover,
        .ant-menu-light .ant-menu-submenu-title:hover
        
        {
          color: var(--primary-color);
        }
        .ant-menu-dark {
          background: var(--sidebar-background);
          color: var(--text-color);
        }
        .ant-menu-horizontal {
          background: var(--sidebar-background);
          color: var(--text-color);
        }
        .ant-table-tbody > tr > td {
          background: var(--sidebar-background);
          color: var(--text-color);
        }
        .ant-table-thead > tr > th,
        .ant-table-tbody > tr.ant-table-placeholder:hover > td
        {
          background: var(--sidebar-background);
          color: var(--text-color);
          filter: brightness(0.95);
        }
        .ant-table-tbody > tr > td :hover,
        .ant-empty-description
        {
          background: var(--sidebar-background);
          color: var(--text-color);
        }
        .ant-table-tbody > tr.ant-table-row:hover > td,
        .ant-table-tbody > tr > td.ant-table-cell-row-hover,
        .ant-table-thead th.ant-table-column-has-sorters:hover,
        .ant-table-row-selected,
        .ant-table-tbody > tr.ant-table-row-selected > td
        {
          background: var(--sidebar-background);
          color: var(--text-color);
          filter: brightness(0.9);
          transition: all 0.3s;
        }
        .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover::after, 
        .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover::after, 
        .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active::after,
        .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active::after,
        .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open::after, 
        .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open::after, 
        .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected::after,
        .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected::after {
          border-bottom: 2px solid var(--primary-color);
        }

        .table,
        .ant-menu-horizontal > .ant-menu-item a,
        .ant-dropdown-menu-title-content,
        .ant-picker-header,
        .ant-picker-cell-inner,
        .ant-form-item-label > label,
        .ant-picker-time-panel-column > li.ant-picker-time-panel-cell .ant-picker-time-panel-cell-inner,
        .ant-picker-content th,
        .ant-picker-header button,
        .ant-menu-item a,
        .ant-menu-submenu-expand-icon, 
        .ant-menu-submenu-arrow,
        .ant-list-item,
        div.ant-typography-h1, 
        div.ant-typography-h1 > textarea, 
        .ant-typography h1,
        h2.ant-typography, 
        div.ant-typography-h2, 
        div.ant-typography-h2 > textarea, 
        .ant-typography h1,
        h3.ant-typography, 
        div.ant-typography-h3, 
        div.ant-typography-h3 > textarea, 
        .ant-typography h3,
         h1.ant-typography, 
        h4.ant-typography, 
        div.ant-typography-h4, 
        div.ant-typography-h4 > textarea, 
        .ant-typography h4,
         h1.ant-typography, 
        h5.ant-typography, 
        div.ant-typography-h5, 
        div.ant-typography-h5 > textarea, 
        .ant-typography h5,
        .ant-tabs
        {
          color: var(--text-color);
        }
        .ant-pagination-item-active {
          background-color: transparent;
        }
        .ant-pagination-item,
        .ant-pagination-item-ellipsis,
        .ant-pagination-item-link,
        .ant-pagination-options-quick-jumper input,
        .anticon,
        .ant-typography,
        .ant-btn-ghost {
          color: var(--text-color);
        }
        .ant-menu-item,
        .ant-menu-item::after,
        .ant-menu-item::before,
        .ant-menu,
        .ant-modal-content,
        .ant-modal-header,
        .ant-modal-title,
        .dropdown-item,
        .ant-menu-submenu-popup > .ant-menu,
        .ant-table-tbody > tr > td,
        .ant-table-filter-dropdown,
        .ant-dropdown-menu,
        .ant-dropdown-menu-item:hover,
        .ant-picker-panel-container,
        .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover::before,
        .ant-picker-cell-in-view.ant-picker-cell-range-start.ant-picker-cell-range-hover::before,
        .ant-picker-cell-in-view.ant-picker-cell-range-end.ant-picker-cell-range-hover::before,
        .ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single).ant-picker-cell-range-hover-start::before,
        .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single).ant-picker-cell-range-hover-end::before, 
        .ant-picker-panel > :not(.ant-picker-date-panel) .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start::before,
        .ant-picker-panel > :not(.ant-picker-date-panel) .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end::before,
        .ant-table-thead th.ant-table-column-sort,
        .ant-table   
        {
          background: var(--sidebar-background);
          color: var(--text-color);
        }
          .ant-picker-range-arrow::before,
          .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected,
          .ant-picker-cell:hover:not(.ant-picker-cell-in-view) .ant-picker-cell-inner,
          .ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single)::before,
          .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single)::before,
          .ant-picker-cell-in-view.ant-picker-cell-in-range::before,
          .ant-dropdown-menu-item-selected,
          .ant-dropdown-menu-submenu-title-selected
          .ant-picker-cell:hover:not(.ant-picker-cell-selected):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end):not(.ant-picker-cell-range-hover-start):not(.ant-picker-cell-range-hover-end) .ant-picker-cell-inner
          {
          background-color: var(--main-background);
          color: var(--text-color);
          }
          .ant-picker-cell-in-view.ant-picker-cell-range-hover-start:not(.ant-picker-cell-in-range):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end)::after, 
          .ant-picker-cell-in-view.ant-picker-cell-range-hover-end:not(.ant-picker-cell-in-range):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end)::after, 
          .ant-picker-cell-in-view.ant-picker-cell-range-hover-start.ant-picker-cell-range-start-single::after, 
          .ant-picker-cell-in-view.ant-picker-cell-range-hover-start.ant-picker-cell-range-start.ant-picker-cell-range-end.ant-picker-cell-range-end-near-hover::after, 
          .ant-picker-cell-in-view.ant-picker-cell-range-hover-end.ant-picker-cell-range-start.ant-picker-cell-range-end.ant-picker-cell-range-start-near-hover::after, 
          .ant-picker-cell-in-view.ant-picker-cell-range-hover-end.ant-picker-cell-range-end-single::after, 
          .ant-picker-cell-in-view.ant-picker-cell-range-hover:not(.ant-picker-cell-in-range)::after {
            border-top: 1px dashed var(--primary-color);
            border-bottom: 1px dashed var(--primary-color);
          }

        .ant-table-thead th.ant-table-column-has-sorters.ant-table-cell-fix-left:hover,
        .ant-table-thead th.ant-table-column-has-sorters.ant-table-cell-fix-right:hover,
        .ant-dropdown-menu-item:hover,
        .ant-dropdown-menu-submenu-title:hover,
        .ant-dropdown-menu-item.ant-dropdown-menu-item-active,
        .ant-dropdown-menu-item.ant-dropdown-menu-submenu-title-active,
        .ant-dropdown-menu-submenu-title.ant-dropdown-menu-item-active,
        .ant-dropdown-menu-submenu-title.ant-dropdown-menu-submenu-title-active {
          filter: brightness(0.9);
          background: var(--sidebar-background);
        }
        .ant-btn-link[disabled] {
          color: var(--text-color);
          filter: brightness(0.9);
        }
        .igoal-data-explorer-transfer > .ant-transfer-list >.ant-transfer-list-header,
        .ant-btn-link[disabled]:hover{
          background: var(--sidebar-background);
          color: var(--text-color);
        }
          .ant-checkbox-indeterminate .ant-checkbox-inner::after,
           .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
          .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
           .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner,
           .ant-picker-range .ant-picker-active-bar {
            background-color: var(--primary-color);
          }
      .ant-btn-primary:hover, 
      .ant-btn-primary:focus,
      .ant-checkbox-checked .ant-checkbox-inner,
      .ant-checkbox-wrapper:hover .ant-checkbox-inner, 
      .ant-checkbox:hover .ant-checkbox-inner, 
      .ant-checkbox-input:focus + .ant-checkbox-inner,
      
      {
          background-color: var(--primary-dark-color);
          border-color: var(--primary-dark-color);
        }
          .ant-picker-time-panel-column > li.ant-picker-time-panel-cell .ant-picker-time-panel-cell-inner:hover{
          background-color: var(--primary-dark-color);
          color: #fff;
          }
          .ant-picker-time-panel-column > li.ant-picker-time-panel-cell .ant-picker-time-panel-cell-inner-selected{
          color: #fff;
      }
        .ant-btn:hover, 
        .ant-btn:focus{
          color: var(--primary-light-color); 
          border-color: var(--primary-light-color);
          background: #fff;
        }

        .ant-select-item-option-selected:not(.ant-select-item-option-disabled){
          background-color: var(--primary-light-color);
        }

          .ant-picker-input > input:focus, 
          .ant-picker-input > input-focused, 
          .ant-picker-focused, 
          .ant-picker:hover, 
          .ant-picker-focused,
          .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector,
          .ant-select:not(.ant-select-disabled):hover .ant-select-selector,
          .ant-input:focus, 
          .ant-input-focused,
          .ant-input-affix-wrapper:focus, 
          .ant-input-affix-wrapper-focused,
          .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover,
          .ant-checkbox-wrapper:hover .ant-checkbox-inner, 
          .ant-checkbox:hover .ant-checkbox-inner, 
          .ant-checkbox-input:focus + .ant-checkbox-inner
          {
            border-color: var(--primary-light-color);
          }
          .ant-picker-focused,
          .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector,
          .ant-input:focus, .ant-input-focused,
          .ant-input-affix-wrapper:focus, 
          .ant-input-affix-wrapper-focused {
            box-shadow: ${shadowPrimary};
          }
          .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected .ant-picker-time-panel-cell-inner {
          background-color: var(--primary-light-color);
            color: #fff;
          }
          .ant-picker-clear > span.anticon.anticon-close-circle,
          .ant-picker-suffix > span.anticon.anticon-calendar, 
          .recharts-tooltip-label {
            color: #000 !important;
          }
          .ant-btn:hover .anticon,
          .ant-picker-header button:hover,
          .ant-input-affix-wrapper:focus, 
          .ant-input-affix-wrapper-focused
          {
            color: var(--primary-light-color);
            transition: all 0.2s;
          }
          .card-solar-status .card-title,
          .card-solar-status svg,
          .card-solar-status h1,
          .card-solar-status h5,
          .card-solar-status p
           {
            color: #2d2c48
          }
          .ant-picker-cell-disabled .ant-picker-cell-inner,
          .ant-tabs-tab.ant-tabs-tab-disabled
          {
            color: var(--disabled);
          }
        `;
      }
      return antdStyles;
    };

    const classesColors = `
    a:hover, a:focus, a:active {
    
      color: var(--primary-color);
      }
      .card, list-group,
      .list-group-item,
      .card-footer {
        background-color: var(--sidebar-background);
        color: var(--text-color);
      }
      .card {
        box-shadow: 0 2px 4px ${theme === "light" ? "#ddd" : "#000000"};
      }
      .btn-secondary.disabled, .btn-secondary:disabled {
        color: var(--text-color);
        background-color: var(--disabled);
        border-color: var(--disabled);
      }

      .list-group-item.active{
        background-color: var(--primary-color);
        color: #fff;
        border-color: var(--primary-dark-color);
      }
      .card-title {
        color: var(--text-color);
      }
      .btn-primary:focus, 
      .btn-primary:hover, 
      .fc-unthemed .fc-button:hover 
      .btn-primary.focus, 
      .fc-unthemed .focus.fc-button,
      .dropdown-item.active, .dropdown-item:active
      {
          background-color: var(--primary-dark-color);
          border-color: var(--primary-dark-color);
        }
      .table{
        color: var(--text-color);
      }
      .list-group-item.selected {
        background-color: var(--main-background);
      }
      .modal-content{
        background: var(--sidebar-background)  ;
        color: var(--text-color)  ;
      }
      .modal-title {
        color: var(--text-color);
      }
      .close {
        color: var(--text-color);
      }
      .close:hover {
        color: mix(var(--text-color), black, 50%);
      }
      .dropdown-item:hover{
        filter: brightness(90%);
      }
      .close,
      .modal-title,
      .ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-ellipsis {
        color: var(--text-color);
      }
      .bg-transparent {
        background-color: transparent  ;
      }
      input,.dropdown-menu {
        background: var(--sidebar-background)  ;
        color: var(--text-color)  ;
      }
      h1, h2, h3, h4, h5, h6, a {
        color: var(--text-color);
      } 
      ::-webkit-scrollbar-thumb {
        background-color: ${theme === "light" ? "#ddd" : "#505050"} !important;
        border: 6px solid ${theme === "light" ? "#fff" : "#1a1d23"}  !important;
        border-radius: 10px;
      }   
      ::-webkit-scrollbar-thumb:hover, 
      ::-webkit-scrollbar-thumb:focus,
      ::-webkit-scrollbar-thumb:active {
        background: ${theme === "light" ? "#ddd" : "#505050"}  !important;
      } 
      
      ::-webkit-scrollbar-track {
        background: ${theme === "light" ? "#fff" : "#1a1d23"}  !important;
        border-color: ${theme === "light" ? "#fff" : "#1a1d23"}  !important;
      }
      ::-webkit-scrollbar-track:hover {
        background: ${theme === "light" ? "#fff" : "#1a1d23"}  !important;
        filter: brightness(110%) !important; 
      }
      ::-webkit-scrollbar{
        background: ${theme === "light" ? "#fff" : "#1a1d23"}  !important;
        border-radius: 10px;
      }
      ::-webkit-scrollbar-track:active {
        background: ${theme === "light" ? "#fff" : "#1a1d23"}  !important;
        filter: brightness(90%) !important; 
      }
      ::-webkit-scrollbar-corner {
        background: transparent !important;
      }
      .row:hover{
        color: var(--text-color);
      }
      .table-striped tbody tr:nth-of-type(odd){
        background-color: var(--sidebar-background);
        color: var(--text-color);
      }
      .main{
        background-color: var(--main-background);
        background-image: var(--bg-image, none);
        background-size: cover;
        background-attachment: fixed;
        background-position: top;
        background-repeat: no-repeat;
        transition: all 0.2s;
      }
      .wrapper * h1.header-title, .breadcrumb-item a, .breadcrumb-item.active {
        color: var(--header-text-color);
      }
      .ant-checkbox-wrapper {
        color: var(--text-color);
      }  
      .spinner-border {
        display: inline-block;
        width: 2rem;
        height: 2rem;
        vertical-align: -0.125em;
        border: 0.25em solid var(--primary-color);
        border-right-color: transparent;
        border-radius: 50%;
        animation: .75s linear infinite spinner-border;
      }
        text.recharts-text.recharts-cartesian-axis-tick-value {
            fill: var(--text-color);
        }     
     .recharts-cartesian-grid line {
          stroke: var(--text-color);
          opacity: 0.3;
      }
    `;

    const currentTheme =
      theme === "light"
        ? { ...lightTheme, ...userColors }
        : { ...darkTheme, ...userColors };

    let cssVariables = "";
    for (const [key, value] of Object.entries(currentTheme)) {
      cssVariables += `${key}: ${value};\n`;
    }
    style.innerHTML = `
        :root {
        ${cssVariables}
      }
        ${generateBootstrapStyles(currentTheme)}
        ${generateAntdStyles(currentTheme)}
       ${classesColors}

      `;
    if (!document.getElementById("variables-css")) {
      const variablesStyle = document.createElement("style");
      variablesStyle.id = "variables-css";
      variablesStyle.innerHTML = `
          :root {
            ${cssVariables}
          }
        `;
      document.head.insertBefore(variablesStyle, document.head.firstChild);
    }

    document.head.appendChild(style);
    const splash = document.getElementsByClassName("splash")[0];
    if (splash) {
      splash.classList.remove("active");
    }
  }, [theme, userColors]);
  useEffect(() => {
    insertCSSVariables();
  }, [theme, insertCSSVariables]);

  const toggleTheme = () => {
    setTheme(theme === "light" ? "dark" : "light");
    localStorage.setItem("defaultTheme", theme === "light" ? "dark" : "light");
  };

  const memoizedColors = useMemo(() => {
    return theme === "light"
      ? { ...DEFAULT_LIGHT_THEME, ...userColors }
      : { ...DEFAULT_DARK_THEME, ...userColors };
  }, [theme, userColors]);

  const handleResetTheme = async() =>{
    localStorage.removeItem("userColors");
    localStorage.removeItem("loadUserPreference");
    await axios.post("/resetUserTheme");
    setLoadUserPreference(false);
  }

  return (
    <ThemeContext.Provider
      value={{
        theme,
        toggleTheme,
        setTheme,
        colors: memoizedColors,
        updateUserColors,
        handleResetTheme
      }}
    >
      {children}
    </ThemeContext.Provider>
  );
};

const useTheme = () => {
  const context = React.useContext(ThemeContext);
  if (context === undefined) {
    throw new Error("useTheme must be used within a ThemeProvider");
  }
  return context;
};

export { ThemeContext, ThemeProvider, useTheme };

